import React from 'react'
import { inject, observer } from 'mobx-react'
import { RecipesStore } from '../../store/RecipesStore'
import { IonIcon, IonLabel, IonItem, IonToolbar, IonToggle } from '@ionic/react'
import { add } from 'ionicons/icons'
import RecipeItemRow from './RecipeItemRow'
import { link } from 'ionicons/icons'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

interface Props {
  recipesStore?: RecipesStore
}

const RecipeItemsList: React.FC<Props> = ({ recipesStore }) => {
  const { editVM } = recipesStore
  if (!editVM) return null
  if (editVM.currentTabIndex !== '1') return null

  const renderNoRecipeItems = () => {
    if (editVM.undeletedRecipeItems.length !== 0) return
    return (
      <IonItem className='no-recipe-items'>
        <IonLabel>No Items</IonLabel>
      </IonItem>
    )
  }

  const renderNewItemRow = () => {
    return (
      <IonItem onClick={() => editVM.addRecipeItem()} className='new-ingredient-row'>
        <IonIcon color='tertiary' icon={add} />
        &nbsp;
        <IonLabel>Add</IonLabel>
      </IonItem>
    )
  }

  const SortableItem = SortableElement(({ e, index }) => {
    return <RecipeItemRow row={e} key={'ri' + e.key} />
  })

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((e, index) => (
          <SortableItem disabled={false} key={'ric' + e.key} index={index} e={e} />
        ))}
      </div>
    )
  })

  const renderRecipeToolbar = () => {
    return (
      <IonToolbar id='toolbar' color='light'>
        <IonItem slot='end' lines='none' onClick={() => editVM.toggleViewLinkedItems()}>
          <IonIcon icon={link} />
          <IonLabel>&nbsp; Link Items</IonLabel>
          <IonToggle color='tertiary' checked={editVM.viewLinkedItems} />
        </IonItem>
      </IonToolbar>
    )
  }

  return (
    <div id='RecipeItemsList'>
      {renderRecipeToolbar()}
      {renderNoRecipeItems()}
      <SortableList
        hideSortableGhost
        useDragHandle
        helperClass='dragging'
        items={editVM.undeletedRecipeItemsOrdered}
        onSortEnd={({ oldIndex, newIndex }) => editVM.reorderRecipeItems(oldIndex, newIndex)}
      />
      {renderNewItemRow()}
    </div>
  )
}

export default inject('recipesStore')(observer(RecipeItemsList))
