import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import agent from '../../Agent'
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in'
import { SocialLogin } from '@capgo/capacitor-social-login'
import { GuestUserMessages } from './guest-user/GuestUserMessages'
import Environment from '../../environment/Environment'

export class GuestUserPageVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    makeObservable(this)
    this.rootStore = rootStore
  }

  @observable public registerModalShown: boolean = false
  @observable public toastShown: boolean = false

  @computed
  public get guestUserId() {
    return this.rootStore.userStore.user.AppUserId
  }

  @action
  private showToast() {
    this.toastShown = true
  }

  public goBack() {
    this.rootStore.appStore.handleGoBack()
  }

  @computed
  public get canSignInWithApple(): boolean {
    if (this.rootStore.appStore.isAndroidNative) return false
    return true
  }

  @computed
  public get hasGoogleLogin() {
    if (!this.rootStore.userStore.user) return
    return this.rootStore.userStore.user.HasGoogleLogin
  }

  @computed
  public get hasAppleLogin() {
    if (!this.rootStore.userStore.user) return
    return this.rootStore.userStore.user.HasAppleLogin
  }

  @action
  public async connectGoogle() {
    await SocialLogin.initialize({
      google: {
        webClientId: '151188122140-glpdjmvcolr8pqc52g4i5ip8kjaelc6i.apps.googleusercontent.com',
      },
    })
    const result = await SocialLogin.login({
      provider: 'google',
      options: {
        scopes: ['email', 'profile'],
      },
    })
    if (!result.result.profile.email) {
      alert('Google login failed. Please try again.')
      return
    }
    try {
      var apiResult = await agent.Users.addGoogleLogin(result.result.profile)
      if (apiResult !== 'success') {
        alert(apiResult)
        return
      }
    } catch (e) {
      alert('Error connecting Google account. Please try again.')
      return
    }
    this.showToast()
    await this.rootStore.userStore.loadUser()
    setTimeout(() => this.rootStore.appStore.navigateTo('/settings'), 3500)
  }

  @action
  public async connectApple() {
    let options: SignInWithAppleOptions = {
      clientId: Environment.appleClientId,
      redirectURI: Environment.appleRedirectUri,
      scopes: Environment.appleScopes,
      state: Environment.appleState,
      nonce: Environment.appleNonce,
    }
    const result: SignInWithAppleResponse = await SignInWithApple.authorize(options)
    if (!result.response?.identityToken) {
      alert('Apple login failed. Please try again.')
      return
    }
    try {
      var apiResult = await agent.Users.addGoogleLogin(result.response)
      if (apiResult !== 'success') {
        alert(apiResult)
        return
      }
    } catch (e) {
      alert('Error connecting Google account. Please try again.')
      return
    }
    this.showToast()
    await this.rootStore.userStore.loadUser()
    setTimeout(() => this.rootStore.appStore.navigateTo('/settings'), 3500)
  }

  @computed
  public get message(): string {
    return GuestUserMessages.messageOptions[Math.floor(Math.random() * GuestUserMessages.messageOptions.length)]
  }

  @action
  public openRegisterModal(): void {
    this.registerModalShown = true
  }

  @action
  public closeRegisterModal(success: boolean): void {
    this.registerModalShown = false
    if (success) {
      setTimeout(() => this.rootStore.appStore.navigateTo('/settings'), 500)
    }
  }

  @action
  public async goToLogin() {
    await this.rootStore.authStore.logout()
    this.rootStore.appStore.navigateTo('/login')
  }
}
