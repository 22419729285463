import { action, computed, makeObservable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { BoardRowVM } from './BoardRowVM'

export class SettingsListVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    makeObservable(this)
    this.rootStore = rootStore
  }

  @computed
  public get isIos() {
    return this.rootStore.appStore.isIos
  }
  
  @computed
  public get canSignInWithApple(): boolean {
    if (this.rootStore.appStore.isAndroidNative) return false
    return true
  }

  @computed
  public get listHeightPx() {
    return this.rootStore.appStore.listHeightPx
  }

  public applyScrollStyles(e) {
    return this.rootStore.appStore.applyScrollStyles(e)
  }

  @computed
  private get hasAppleLogin() {
    if (!this.rootStore.userStore.user) return
    return this.rootStore.userStore.user.HasAppleLogin
  }

  @computed
  public get appleConnectedMessage() {
    if (!this.hasAppleLogin) return 'Link'
    return 'Linked'
  }

  @computed
  private get hasGoogleLogin() {
    if (!this.rootStore.userStore.user) return
    return this.rootStore.userStore.user.HasGoogleLogin
  }

  @computed
  public get googleConnectedMessage() {
    if (!this.hasGoogleLogin) return 'Link'
    return 'Linked'
  }

  @computed
  public get currentBoardName() {
    return this.rootStore.boardsStore.currentBoard?.Name
  }

  @computed
  public get currentBoardMembersCount() {
    return this.rootStore.boardsStore.currentBoard?.BoardUsers.length
  }

  @computed
  public get currentBoardInvitationCode() {
    return this.rootStore.boardsStore.currentBoard?.InvitationCode
  }

  @computed
  public get currentBoardInvitationsCount() {
    return this.rootStore.boardsStore.currentBoard?.BoardInvitations.length
  }

  @computed
  public get themeMessage() {
    return this.rootStore.appStore.themeGenVM.currentTheme
  }

  @action
  public goTo(path: string) {
    this.rootStore.appStore.navigateTo(path)
  }

  @action
  public logout() {
    this.rootStore.authStore.logout()
    this.rootStore.appStore.navigateTo('/login')
  }

  @computed
  public get boards() {
    return this.rootStore.boardsStore.persistedRecords
      .slice()
      .sort((a, b) => (a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 1))
      .map((e) => new BoardRowVM(this.rootStore, e))
  }

  @action
  public openWebsite() {
    window.open('https://groceryboard.io')
  }

  @action
  public openFAQ() {
    window.open('https://groceryboard.io/faqs')
  }

  @action
  public openContact() {
    window.open('https://groceryboard.io/contact/')
  }

  @action
  public openBoard(boardGuid) {
    const board = this.rootStore.boardsStore.get(boardGuid)
    this.rootStore.boardsStore.setCurrentBoard(board.BoardId)
    // this.goTo('/boardsettings')
  }

  @action
  public openNewBoardModal() {
    this.rootStore.boardsStore.newVM.open()
  }

  @action
  public openJoinBoardModal() {
    this.rootStore.boardsStore.joinVM.toggle()
  }

  @computed
  public get key() {
    return this.rootStore.boardsStore.persistedRecords.length
  }
}
