import { action, makeObservable, observable, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import agent from '../../Agent'

export class ChangePasswordModalVM {
  private focused: boolean = false

  constructor(private rootStore: RootStore, private onClose: () => void) {
    makeObservable(this)
  }

  @observable public newPassword: string = ''
  @observable public confirmPassword: string = ''
  @observable public isProcessing: boolean = false
  @observable public errorMessages: string[] = []
  @observable public passwordShown: boolean = false
  @observable public toastShown: boolean = false

  @action
  public toggleShowPassword() {
    this.passwordShown = !this.passwordShown
  }

  @action
  public setNewPassword(password: string) {
    this.newPassword = password
    this.clearErrorMessages()
  }

  @action
  private clearErrorMessages() {
    this.errorMessages = []
  }

  @action
  public setConfirmPassword(password: string) {
    this.confirmPassword = password
    this.clearErrorMessages()
  }

  public setFocus(e: HTMLIonInputElement): void {
    if (this.focused) return
    if (!e) return
    this.focused = true
    e.setFocus()
    setTimeout(() => e.setFocus(), 100)
  }

  public setTabIndex(e: any, idx: number) {
    if (!e) return
    if (!e.nativeInput) {
      setTimeout(() => this.setTabIndex(e, idx), 100)
      return
    }
    e.nativeInput.tabIndex = idx
  }

  @action
  public async handleSave() {
    this.errorMessages = []
    if (this.newPassword.length === 0) {
      this.errorMessages = ['Password cannot be empty']
      return
    }
    if (this.confirmPassword.length === 0) {
      this.errorMessages = ['Confirm Password cannot be empty']
      return
    }
    if (this.newPassword !== this.confirmPassword) {
      this.errorMessages = ['Passwords do not match']
      return
    }
    this.isProcessing = true
    const success = await this.tryRegister({ password: this.newPassword })
    runInAction(() => {
      if (success) {
        this.toastShown = true
        setTimeout(() => this.onClose(), 2000)
      }
      this.isProcessing = false
    })
  }

  private async tryRegister(form) {
    try {
      const results: string[] = await agent.Users.changePassword(form)
      if (results[0] !== 'Success' && results.length === 1) {
        results.forEach((e) => this.errorMessages.push(e))
      }
    } catch (e) {
      console.error(e)
      return false
    }
    return true
  }
}
