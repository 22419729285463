import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { UserStore } from '../store/UserStore'
import UserAvatar from 'react-user-avatar'
import AvatarColors from '../../theme/AvatarColors'
import { AppStore } from '../../stores/AppStore'
import '../styles/AccountMenu.scss'
import { MaterialCommunityIcons } from 'react-web-vector-icons'
import { notificationsOutline } from 'ionicons/icons'
import { RootStore } from '../../stores/RootStore'
import { AccountMenuVM } from '../view-models/AccountMenuVM'

interface Props {
  rootStore?: RootStore
}

const AccountMenu: React.FC<Props> = ({ rootStore }) => {
  const vm = useLocalObservable(() => new AccountMenuVM(rootStore))

  const renderNotificationIcon = () => {
    if (!vm.notificationShown) return
    return (
      <IonButton onClick={() => vm.goToSettings()}>
        <IonIcon icon={notificationsOutline} />
        <MaterialCommunityIcons name='numeric-1-box' size={16} />
      </IonButton>
    )
  }

  return (
    <div id='AccountMenu'>
      {renderNotificationIcon()}
      <IonButton onClick={() => vm.goToSettings()}>
        <UserAvatar size='36' colors={AvatarColors} name={vm.userName} />
      </IonButton>
    </div>
  )
}

export default inject('rootStore')(observer(AccountMenu))
