import { RootStore } from 'src/stores/RootStore'
import { observable, action, runInAction, computed, makeObservable } from 'mobx'
import agent from '../../Agent'
import BoardInvitation from '../../board-invitations/aggregate/BoardInvitation'
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in'
import { IGuestRegistrationResult } from '../interfaces/IGuestRegistrationResult'
import { ILoginResult } from '../interfaces/ILoginResult'
import { Device } from '@capacitor/device'
import { SocialLogin, LoginResult, GoogleLoginResponse } from '@capgo/capacitor-social-login'
import Environment from '../../environment/Environment'

export class LoginVM {
  private rootStore: RootStore
  private to: NodeJS.Timeout
  private DEBUG: boolean = false

  constructor(rootStore) {
    makeObservable(this)
    this.rootStore = rootStore
    // this.checkForInput()
    this.loadBoardInvitation()
    if (Environment.isDevMode) this.DEBUG = true
  }

  @observable public isProcessing = false
  @observable public errorMsg: string = ''
  @observable public email: string = ''
  @observable public password: string = ''
  @observable public hasEmail: boolean = false
  @observable public hasPassword: boolean = false
  @observable public loginTried: boolean = false
  @observable public boardInvitation: BoardInvitation = null
  @observable public acceptBoardInvitation: boolean = false
  @observable public showGoogleSpinner: boolean = false
  @observable public showAppleSpinner: boolean = false
  @observable public showGuestSpinner: boolean = false

  @action
  public toggleAcceptBoardInvitation() {
    this.acceptBoardInvitation = !this.acceptBoardInvitation
  }

  @action
  public async loadBoardInvitation() {
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('inv')
    if (!id) return
    this.boardInvitation = await agent.BoardInvitations.get(id)
    if (this.boardInvitation) {
      runInAction(() => (this.acceptBoardInvitation = true))
    }
  }

  @action
  private checkForInput() {
    // this.to = setTimeout(() => {
    //   runInAction(() => {
    //     try {
    //       const email = document.getElementById('email').getElementsByTagName('input')[0].value
    //       const pw = document.getElementById('password').getElementsByTagName('input')[0].value
    //       this.setEmail(email)
    //       this.setPassword(pw)
    //     } catch (e) {}
    //   })
    // }, 2000)
  }

  @computed
  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }

  @computed
  public get canSignInWithApple(): boolean {
    if (this.rootStore.appStore.isAndroidNative) return false
    return true
  }

  @computed
  public get spinnerName(): string {
    if (this.rootStore.appStore.isIos) return 'cirles'
    return 'crescent'
  }

  @computed
  public get showSpinner() {
    return this.isProcessing
  }

  @action
  public async submit() {
    if (this.isProcessing) {
      console.log('nope')
      return
    }
    this.isProcessing = true
    const email = document.getElementById('email').getElementsByTagName('input')[0].value
    const pw = document.getElementById('password').getElementsByTagName('input')[0].value
    this.setEmail(email)
    this.setPassword(pw)
    const success = await this.tryLogin()
    if (success) {
      await this.processAcceptBoardInvitation()
      this.rootStore.loadData('user logged in')
      this.goToHomeAfterSuccess()
    }
  }

  @action
  public async processAcceptBoardInvitation() {
    if (this.boardInvitation && this.acceptBoardInvitation) {
      const postData = { BoardInvitationGuid: this.boardInvitation.BoardInvitationGuid }
      await agent.BoardInvitations.accept(postData)
    }
  }

  @action
  public setEmail(email) {
    this.hasEmail = true
    this.errorMsg = undefined
    this.email = email.trim()
  }

  @action
  public setPassword(password) {
    this.hasPassword = true
    this.errorMsg = undefined
    this.password = password
  }

  @action
  public goToRegister() {
    let url = '/register'
    if (this.boardInvitation) url += '?inv=' + this.boardInvitation.BoardInvitationGuid
    this.rootStore.appStore.navigateTo(url)
  }

  public goToContactPage() {
    window.location.href = 'https://groceryboard.io/contact'
  }

  @action
  public goToForgotPassword() {
    const vm = this.rootStore.userStore.forgotPasswordVM
    if (vm) vm.setEmail(this.email)
    this.rootStore.appStore.navigateTo('/forgot-password')
  }

  @action
  public async tryLogin(): Promise<boolean> {
    this.errorMsg = undefined
    this.rootStore.appStore.setToken(null)
    if (this.email === '') {
      this.errorMsg = 'Please enter a valid email address'
      this.isProcessing = false
      return false
    }
    if (this.password === '') {
      this.errorMsg = 'Please enter a password'
      this.isProcessing = false
      return false
    }
    try {
      const result: ILoginResult = await agent.Auth.login(this.email, this.password)
      if (result.Success && result.Token) {
        this.rootStore.appStore.setToken(result.Token)
        this.rootStore.appStore.firebaseSvc.logEvent('login', { method: 'email' })
        return true
      } else {
        const msg = result.Errors.join('. ')
        this.errorMsg = msg
        this.isProcessing = false
        return false
      }
    } catch (err) {
      const msg = 'Unknown error. Please try again shortly.'
      console.error(err)
      this.isProcessing = false
      this.errorMsg = msg
      throw err
    }
  }

  @action
  public async continueWithGoogle() {
    this.rootStore.authStore.setGoogleLoginInfo(null)
    if (this.DEBUG) console.log('Starting Google Login')
    let result: LoginResult = undefined
    this.showGoogleSpinner = true
    try {
      await SocialLogin.initialize({
        google: {
          webClientId: '151188122140-glpdjmvcolr8pqc52g4i5ip8kjaelc6i.apps.googleusercontent.com',
          iOSClientId: '151188122140-71o0ots9m80mif915a95e0giruhrvkm4.apps.googleusercontent.com',
        },
      })
      result = await SocialLogin.login({
        provider: 'google',
        options: {
          scopes: ['email', 'profile'],
        },
      })
      if (!result) alert('no response: ' + String(result))
    } catch (e) {
      runInAction(() => (this.showGoogleSpinner = false))
      console.error(e)
      return
    }
    if (this.DEBUG) {
      // const ex = {
      //   provider: 'google',
      //   result: {
      //     idToken:
      //       'eyJhbGciOiJSUzI1NiIsImtpZCI6ImRkMTI1ZDVmNDYyZmJjNjAxNGFlZGFiODFkZGYzYmNlZGFiNzA4NDciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIxNTExODgxMjIxNDAtYWN2NjEwaTlrc2RtbWlnZjY5czE1MmppcmV1OTByMDEuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiIxNTExODgxMjIxNDAtZ2xwZGptdmNvbHI4cHFjNTJnNGk1aXA4a2phZWxjNmkuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMDY3MzA4MjY1OTMzMjU2NzY2MTUiLCJlbWFpbCI6ImVsZXhpZW50QGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoiTWFyayBIb3dhcmQiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jSWFFYzBpNHF4Nm9zWFRPMUV3NExnWXQ3Z0cxc0E1dEFiRU1iUjVDQmNhcGFPeWlrai09czk2LWMiLCJnaXZlbl9uYW1lIjoiTWFyayIsImZhbWlseV9uYW1lIjoiSG93YXJkIiwiaWF0IjoxNzM2OTU2MTY4LCJleHAiOjE3MzY5NTk3Njh9.cCKpDIHo90nOOKjvT7X8Ih3vb1dCo5NA8LiSNeDLnkL7TarJ1mAGrzc0GT7eosRAsg37DrQFa_wcI3RNuhCyvkLtwOS1fJKe1MHMDJmnfim5IcD044Q6ZE8q1mMkSKDfkU_f-OyqfHqovbu8gJUQkjOfl-bKC4UOmRNaC000MetZPtRXBQa0nwKgwmhRlQ57mLFvsGA2KwxwOsP487op2f4uh0H4agpsFHm7a4Ph7WPPBhbuCliuqdqMbdqhrwr04Ouf1M-v4PXuiJ5xPBGi6oyzpYXAYg4BYD942t2iOHlnSjwuJJPwPB4UnhGOf-DRfTVdXTcvF4bxvccG-yu0gg',
      //     accessToken: {
      //       token:
      //         'ya29.a0ARW5m773E1Lv4l0pn2kIuyOeZ7pUg4GnQBJLBbD7TrAZB1bNWkI_-CvgQSKOOcECeOFBbGf_XM-rtLoB_42xPF41WAs0jKuMeeqNQkkQH7S31ZvHKtw_deRzkUIERN1vi_DmjT7e7BRNvD8z4hQnVG69ekXVeNAoJg0XjQhv4waCgYKAUUSARMSFQHGX2MiuATb_nmDTWUwYMrSt5Td9w0177',
      //       userId: 'elexient@gmail.com',
      //     },
      //     profile: {
      //       id: 'elexient@gmail.com',
      //       name: 'Mark Howard',
      //       email: 'elexient@gmail.com',
      //       familyName: 'Howard',
      //       givenName: 'Mark',
      //       imageUrl:
      //         'https://lh3.googleusercontent.com/a/ACg8ocIaEc0i4qx6osXTO1Ew4LgYt7gG1sA5tAbEMbR5CBcapaOyikj-=s96-c',
      //     },
      //   },
      // }

      // const exIdTokenExpanded = {
      //   iss: 'https://accounts.google.com',
      //   azp: '151188122140-acv610i9ksdmmigf69s152jireu90r01.apps.googleusercontent.com',
      //   aud: '151188122140-glpdjmvcolr8pqc52g4i5ip8kjaelc6i.apps.googleusercontent.com',
      //   sub: '106730826593325676615', //106730826593325676615
      //   email: 'elexient@gmail.com',
      //   email_verified: true,
      //   name: 'Mark Howard',
      //   picture: 'https://lh3.googleusercontent.com/a/ACg8ocIaEc0i4qx6osXTO1Ew4LgYt7gG1sA5tAbEMbR5CBcapaOyikj-=s96-c',
      //   given_name: 'Mark',
      //   family_name: 'Howard',
      //   iat: 1736956168,
      //   exp: 1736959768,
      // }

      console.log('GOOGLE RESPONSE:' + JSON.stringify(result))
    }
    if (result.result.profile.email) {
      this.rootStore.authStore.setGoogleLoginInfo(result.result as GoogleLoginResponse)
      const success = await this.tryGoogleLogin(result.result as GoogleLoginResponse)
      if (success) {
        await this.processAcceptBoardInvitation()
        this.rootStore.loadData('user logged in')
        this.goToHomeAfterSuccess()
      } else {
        runInAction(() => (this.showGoogleSpinner = false))
        this.rootStore.authStore.loadRegistrationVM()
        this.rootStore.appStore.navigateTo('/register')
      }
    }
  }

  @action
  private async tryGoogleLogin(googleResponse: GoogleLoginResponse): Promise<boolean> {
    this.errorMsg = undefined
    this.rootStore.appStore.setToken(null)
    try {
      if (this.DEBUG) {
        console.log('HERE BRO: ' + JSON.stringify(googleResponse))
      }
      this.processAndroidNativeResponse(googleResponse)
      const result = await agent.Auth.tryGoogleLogin({
        userName: googleResponse.profile.name,
        firstName: googleResponse.profile.givenName,
        lastName: googleResponse.profile.familyName,
        email: googleResponse.profile.email,
        googleUserId: googleResponse.profile.id,
      })
      if (this.DEBUG) {
        console.log(result)
      }
      if (result !== 'not_found') {
        this.rootStore.appStore.setToken(result)
        this.rootStore.appStore.firebaseSvc.logEvent('login', { method: 'google' })
        return true
      }
      return false
    } catch (err) {
      let msg = 'Unknown Error'
      if (err.toString().indexOf('Request has been terminated')) msg = 'App offline. Please try again shortly.'
      console.error(err)
      console.log('LOGIN FAILED: ' + err.toString())
      this.isProcessing = false
      this.errorMsg = msg
      return false
    }
  }

  private processAndroidNativeResponse(googleResponse: GoogleLoginResponse) {
    const hasValidEmail = Boolean(googleResponse.profile.email.indexOf('@'))
    if (hasValidEmail) return
    const idTokenDecoded = JSON.parse(atob(googleResponse.idToken.split('.')[1]))
    googleResponse.profile.id = idTokenDecoded.sub
  }

  @action
  private async tryAppleLogin(appleUserToken: string): Promise<boolean> {
    this.errorMsg = undefined
    this.rootStore.appStore.setToken(null)
    try {
      const result: any = await agent.Auth.tryAppleLogin(
        appleUserToken,
        this.rootStore.authStore.appleLoginInfoUserName
      )
      if (result.Email) this.rootStore.authStore.appleLoginInfo.email = result.Email
      if (result.GroceryBoardToken) {
        this.rootStore.appStore.setToken(result.GroceryBoardToken)
        this.rootStore.appStore.firebaseSvc.logEvent('login', { method: 'apple' })
        return true
      }
      return false
    } catch (err) {
      let msg = 'Unknown Error'
      if (err.toString().indexOf('Request has been terminated')) msg = 'App offline. Please try again shortly.'
      console.error(err)
      console.log('LOGIN FAILED: ' + err.toString())
      this.isProcessing = false
      this.errorMsg = msg
      throw err
    }
  }

  @action
  public async continueWithApple() {
    const thisDomain = window.location.origin
    this.rootStore.authStore.setAppleLoginInfo(null)
    let options: SignInWithAppleOptions = {
      clientId: Environment.appleClientId,
      redirectURI: Environment.appleRedirectUri,
      scopes: Environment.appleScopes,
      state: Environment.appleState,
      nonce: Environment.appleNonce,
    }
    const result: SignInWithAppleResponse = await SignInWithApple.authorize(options)
    if (result.response && result.response.identityToken) {
      this.showAppleSpinner = true
      this.rootStore.authStore.setAppleLoginInfo(result.response)
      console.log(result)
      const success = await this.tryAppleLogin(result.response.identityToken)
      if (success) {
        await this.processAcceptBoardInvitation()
        this.rootStore.loadData('user logged in')
        this.goToHomeAfterSuccess()
      } else {
        this.showAppleSpinner = false
        this.isProcessing = false
        this.rootStore.authStore.loadRegistrationVM()
        this.rootStore.appStore.navigateTo('/register')
      }
    }
  }

  @action
  public async continueAsGuest() {
    this.showGuestSpinner = true
    const id = await Device.getId()
    console.log(id)
    const frm = {
      UUID: id.identifier,
    }
    const result: IGuestRegistrationResult = await agent.Auth.tryGuestRegister(frm)
    console.log(result)
    if (result.Success && result.Token) {
      this.rootStore.appStore.setToken(result.Token)
      await this.processAcceptBoardInvitation()
      this.rootStore.loadData('user logged in')
      this.goToHomeAfterSuccess()
    } else {
      this.showGuestSpinner = false
      this.isProcessing = false
      this.errorMsg = result.Errors.join(' ')
    }
  }

  @action
  private goToHomeAfterSuccess() {
    setTimeout(() => {
      runInAction(() => {
        this.isProcessing = false
        this.showGuestSpinner = false
        this.showAppleSpinner = false
        this.showGoogleSpinner = false
        this.rootStore.appStore.themeGenVM.loadTheme('system')
        this.rootStore.appStore.navigateTo('/')
      })
    }, 2000)
  }
}
