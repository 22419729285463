import React from 'react'
import { IonSpinner } from '@ionic/react'
import Icon from '../assets/images/Icon_512.png'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { RedirectVM } from './RedirectVM'
import { RootStore } from '../stores/RootStore'

interface Props {
  rootStore?: RootStore
}

const RedirectScreen: React.FC<Props> = ({ rootStore }) => {
  const vm = useLocalObservable(() => new RedirectVM(rootStore))

  const renderSpinner = () => {
    if (!vm.spinnerShown) return null
    return (
      <div id='SpinnerContainer'>
        <IonSpinner name='crescent' />
      </div>
    )
  }

  const renderLinks = () => {
    if (!vm.linksShown) return null
    return (
      <>
        <div id='LinksContainer'>
          <a href='https://apps.apple.com/us/app/grocery-board/id1471128617'>
            <img
              src={'https://i0.wp.com/groceryboard.io/wp-content/uploads/2020/05/app-store.png?resize=230%2C72&ssl=1'}
              alt='Play Store'
            />
          </a>
          <br />
          <a href='https://play.google.com/store/apps/details?id=com.groceryboard.app'>
            <img
              src={'https://i0.wp.com/groceryboard.io/wp-content/uploads/2020/05/google-play.png?resize=230%2C72&ssl=1'}
              alt='Play Store'
            />
          </a>
          <br />
          <a href='https://apps.microsoft.com/store/detail/grocery-board/9PKT2R4Z8GBZ'>
            <img
              src={'https://i0.wp.com/groceryboard.io/wp-content/uploads/2021/01/English_get-it-from-MS.png?resize=300%2C108&ssl=1'}
              alt='Play Store'
            />
          </a>
        </div>
      </>
    )
  }

  return (
    <div id='RedirectScreen'>
      <div id='LogoContainer'>
        <img src={Icon} alt='Grocery Board' />
        <div>
          <div id='g'>Grocery</div>
          <div id='b'>Board</div>
        </div>
      </div>
      {renderLinks()}
      {renderSpinner()}
    </div>
  )
}

export default inject('rootStore')(observer(RedirectScreen))
