import React from 'react'
import { IonButton, IonItem, IonText, IonLabel } from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { AntDesign, EvilIcons } from 'react-web-vector-icons'
import { UserEditVM } from '../../view-models/UserEditVM'

interface Props {
  vm: UserEditVM
}

const AppleLogin: React.FC<Props> = ({ vm }) => {
  const renderRemoveAppleLogin = () => {
    if (!vm.hasAppleLogin) return
    return (
      <IonText>
        <EvilIcons name='check' size={26} style={{ color: 'var(--ion-color-success)', marginRight: '5px' }} />
        <strong>Connected</strong>
      </IonText>
    )
  }

  const renderConnectAppleLogin = () => {
    if (vm.hasAppleLogin) return
    return <IonButton onClick={() => vm.connectApple()}>Connect</IonButton>
  }

  return (
    <IonItem lines={'inset'}>
      <IonLabel>
        <AntDesign name='apple1' size={20} />
        Apple
      </IonLabel>
      {renderRemoveAppleLogin()}
      {renderConnectAppleLogin()}
    </IonItem>
  )
}

export default inject('userStore')(observer(AppleLogin))
