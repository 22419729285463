import React from 'react'
import { inject } from 'mobx-react'
import AccountSettingsPageContent from '../account/AccountSettingsPageContent'
import { AppStore } from '../../../stores/AppStore'

interface Props {
  appStore?: AppStore
  history: any
}

@inject('appStore')
export default class AccountSettingsPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/accountsettings')
  }

  render() {
    return <AccountSettingsPageContent />
  }
}
