import React from 'react'
import { IonButton, IonIcon, IonItem } from '@ionic/react'
import { RootStore } from '../../../stores/RootStore'
import { inject, observer } from 'mobx-react'
import { ItemsStore } from '../../store/ItemsStore'
import { cameraOutline, sparklesSharp } from 'ionicons/icons'

interface Props {
  rootStore?: RootStore
  itemsStore?: ItemsStore
}

const ImageButtons: React.FC<Props> = ({ rootStore, itemsStore }) => {
  const { editVM } = itemsStore
  if (!editVM) return null
  if (editVM.currentTabIndex !== '0') return null
  if (!editVM.hasAIItemImagesFeatureEnabled) return null

  const renderUploadButton = () => {
    return (
      <IonButton onClick={() => editVM.takePhoto()} size='large'>
        <IonIcon icon={cameraOutline} size='small' />
      </IonButton>
    )
  }

  const renderGenerateImageButton = () => {
    return (
      <IonButton onClick={() => editVM.openGenerateImage()} size='large'>
        <IonIcon icon={sparklesSharp} size='small' />
      </IonButton>
    )
  }

  return (
    <>
      <IonItem>
        {renderUploadButton()}
        {renderGenerateImageButton()}
      </IonItem>
    </>
  )
}

export default inject('rootStore', 'itemsStore')(observer(ImageButtons))
