import React from 'react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonList,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { close } from 'ionicons/icons'
import { UserEditVM } from '../../view-models/UserEditVM'
import AppleLogin from './AppleLogin'
import GoogleLogin from './GoogleLogin'
import Password from './Password'
import PersonalInfo from './PersonalInfo'
import { checkmarkSharp } from 'ionicons/icons'
import { UserStore } from '../../store/UserStore'
import { RootStore } from '../../../stores/RootStore'
import BackIcon from '../../../shared/BackIcon'

interface Props {
  rootStore?: RootStore
  userStore?: UserStore
}

const AccountSettingsPageContent: React.FC<Props> = ({ rootStore }) => {
  const vm = useLocalObservable(() => new UserEditVM(rootStore))

  const renderBlankHeader = () => {
    if (!rootStore.appStore.showAppHeader) return
    return (
      <IonHeader>
        <IonToolbar />
      </IonHeader>
    )
  }

  return (
    <>
      {renderBlankHeader()}
      <IonHeader id='AccountSettingsPage_Header'>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonButton onClick={() => vm.goBack()}>
              <BackIcon />
            </IonButton>
          </IonButtons>

          <IonTitle>My Account</IonTitle>

          <IonButtons slot='end'>
            <IonButton onClick={() => vm.cancel()} hidden={!vm.saveEnabled}>
              <IonIcon icon={close} />
            </IonButton>
            <IonButton
              onClick={(e) => {
                e.preventDefault()
                vm.save()
              }}
              disabled={!vm.saveEnabled}
            >
              <IonIcon icon={checkmarkSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id='AccountSettingsPage_Content'>
        <div className='body'>
          <IonList mode='md'>
            <PersonalInfo vm={vm} />
            <GoogleLogin vm={vm} />
            <AppleLogin vm={vm} />
            <Password vm={vm} />
          </IonList>
        </div>
      </IonContent>
      <IonFab vertical='bottom' horizontal='end'>
        <IonFabButton
          color='tertiary'
          onClick={(e) => {
            e.preventDefault()
            vm.save()
          }}
          disabled={!vm.saveEnabled}
          style={{
            marginTop: '-60px',
            marginLeft: '-60px',
          }}
        >
          <IonIcon icon={checkmarkSharp} />
        </IonFabButton>
      </IonFab>
    </>
  )
}

export default inject('rootStore', 'userStore')(observer(AccountSettingsPageContent))
