import Icon from '../../assets/images/Icon_512.png'
import './LogoHeader.scss'

const LogoHeader = () => {
  return (
    <div id='LogoHeader'>
      <div>
        <div id='g'>Grocery</div>
        <div id='b'>Board</div>
      </div>
      <img src={Icon} alt='Grocery Board' />
    </div>
  )
}

export default LogoHeader
