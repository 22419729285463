export class TextUtils {
  public static disableAutoComplete(e: any) {
    if (!e) return
    if (!e.nativeInput) {
      setTimeout(() => TextUtils.disableAutoComplete(e), 100)
      return
    }
    const txt = e.nativeInput
    txt.setAttribute('autocomplete', '9something-new' + Math.random())
  }
}
