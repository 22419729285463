import { CategoryNewVM } from '../../../items-select/view-models/CategoryNewVM'
import { RootStore } from 'src/stores/RootStore'
import { computed, observable, action, makeObservable } from 'mobx'
import { Item } from '../../aggregate/Item'
import { ItemsService } from '../../services/ItemsService'
import { Category } from '../../../categories/aggregate/Category'
import { TextUtils } from '../../../shared/TextUtils'

export class ItemEditModalVM {
  private onCompleted: (qty?: number) => void

  constructor(
    private rootStore: RootStore,
    private itemGuid: string,
    options: {
      backdropShown: boolean
      onCompleted: (qty?: number) => void
    }
  ) {
    makeObservable(this)
    this.rootStore = rootStore
    this.item = this.rootStore.itemsStore.get(itemGuid).clone()
    window.location.hash = '/newitem'
    this.backdropShown = options.backdropShown
    setTimeout(() => window.addEventListener('hashchange', () => this.hide()), 500)
    this.onCompleted = options.onCompleted
  }

  @observable public item: Item = null
  @observable public deleteConfirmShown: boolean = false
  @observable public currentTabIndex: string = '0'
  @observable public categoryNewVM: CategoryNewVM = null
  @observable public shown: boolean = true
  @observable public quantity: number = 1
  @observable public doAdd: boolean = true
  @observable public backdropShown: boolean = false
  @observable public newItemTypeName: string = ''
  @observable public addToShown: boolean = true

  @computed
  public get categoryGuid(): string {
    return this.item.CategoryGuid
  }

  @computed
  public get categories(): Category[] {
    if (!this.rootStore.categoriesStore) return []
    const cats = this.rootStore.categoriesStore.currentBoardRecords
      .sort((a, b) => {
        const aVal = a.Name.toLowerCase()
        const bVal = b.Name.toLowerCase()
        if (aVal < bVal) return -1
        if (aVal > bVal) return 1
        return 0
      })
      .slice()
    cats.push({ CategoryGuid: 'new', Name: 'Add New...' } as Category)
    return cats
  }

  @action
  public setCategory(val) {
    if (val === 'new') {
      this.categoryNewVM = new CategoryNewVM(this.rootStore, this)
      this.categoryNewVM.toggleShown()
      this.item.setCategory(null)
      return
    }
    this.item.setCategory(val)
  }

  @action
  public toggleDoAddItem() {
    this.doAdd = !this.doAdd
    if (!this.doAdd) this.quantity = 0
    else this.quantity = 1
  }

  @action
  public setQuantity(val: string) {
    if (val === '' || !val || Number(val) < 0) val = '0'
    this.quantity = Number(val)
    if (this.quantity === 0) this.toggleDoAddItem()
  }

  @computed
  public get hasQuantity(): boolean {
    return this.quantity !== 0
  }

  @action
  public increaseQuantity() {
    this.quantity++
  }

  @action
  public decreaseQuantity() {
    this.quantity--
    if (this.quantity === 0) this.toggleDoAddItem()
  }

  @action
  public hide() {
    this.shown = false
  }

  @action
  public show() {
    this.shown = true
  }

  @action
  public cancel() {
    this.shown = false
    if (this.rootStore.itemsStore.currentBoardRecords.length === 0) {
      this.rootStore.appStore.navigateTo('/')
    }
  }

  @computed
  public get name(): string {
    console.log(this.item)
    if (!this.item) return ''
    return this.item.Name
  }

  @action
  public setName(val: string) {
    this.item.setName(val)
  }

  @computed
  public get isValid(): boolean {
    if (this.name === '') return false
    if (!this.categoryGuid) return false
    return true
  }

  public showCategoryNewModal() {
    this.categoryNewVM.toggleShown()
  }

  @computed
  public get iconName(): string {
    if (this.newItemTypeName === 'Shopping List' && this.doAdd) return 'cart'
    if (this.doAdd) return 'checkmark'
    return ''
  }

  @action
  public save() {
    const svc = new ItemsService(this.rootStore)
    svc.saveItem(this.item.toDTO())
    this.hide()
    this.onCompleted(this.quantity)
  }
}
