import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { SettingsListVM } from '../view-models/SettingsListVM'
import { RootStore } from '../../stores/RootStore'
import SettingsList from './SettingsList'
import { IonContent } from '@ionic/react'

interface Props {
  rootStore?: RootStore
}

const SettingsListArea: React.FC<Props> = ({ rootStore }) => {
  const vm = useLocalStore(() => new SettingsListVM(rootStore))

  return (
    <IonContent
      id='SettingsPage_Content'
      scrollY
      ref={(e) => vm.applyScrollStyles(e)}
      style={{ height: vm.listHeightPx }}
    >
      <SettingsList vm={vm} />
      <br />
      <br />
    </IonContent>
  )
}

export default inject('rootStore')(observer(SettingsListArea))
