import React from 'react'
import { inject, observer, useLocalStore, useLocalObservable } from 'mobx-react'
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonTitle } from '@ionic/react'
import { EvilIcons, MaterialCommunityIcons } from 'react-web-vector-icons'
import { RootStore } from '../../../stores/RootStore'
import { GuestUserCardVM } from '../../view-models/guest-user/GuestUserCardVM'

interface Props {
  rootStore?: RootStore
}

const GuestUserCard: React.FC<Props> = ({ rootStore }) => {
  const vm = useLocalObservable(() => new GuestUserCardVM(rootStore))
  if (!vm.shown) return null

  return (
    <IonCard id='GuestUserCard'>
      <IonCardHeader color='medium'>
        <IonCardTitle>
          <MaterialCommunityIcons name='alert' size={29} />
          Welcome Guest User
        </IonCardTitle>
        <div className='right'>
          <MaterialCommunityIcons name='key' size={29} />
        </div>
      </IonCardHeader>
      <IonCardContent>
        {vm.message}
        <br />
      </IonCardContent>
      <div className='buttons'>
        <IonButton color='tertiary' onClick={() => vm.goToRegister()}>
          Register
        </IonButton>
      </div>
    </IonCard>
  )
}

export default inject('rootStore')(observer(GuestUserCard))
