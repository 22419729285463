import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import App from './App'
import { Provider } from 'mobx-react'
import promiseFinally from 'promise.prototype.finally'
import './offline.min'
import { RootStore } from './stores/RootStore'
import * as Sentry from '@sentry/browser'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { Capacitor } from '@capacitor/core'
import './theme/offline.theme.dark.scss'
import { configurePersistable } from 'mobx-persist-store'
import { StatusBar, Style } from '@capacitor/status-bar'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { setupIonicReact } from '@ionic/react'
import Environment from './environment/Environment'

console.log('DEVMODE: ' + Environment.isDevMode)

setupIonicReact({})

let doSentry = true
const reportOnLocal = false
if (Environment.isDevMode && !reportOnLocal) doSentry = false
let environment = 'Production'
if (Environment.isDevMode) environment = 'Development'
if (doSentry) {
  Sentry.init({
    dsn: 'https://5df06671b060451ea9d08d4c8caae26c@o391845.ingest.sentry.io/5240824',
    environment: environment,
    beforeSend(event, hint) {
      if (event.message && event.message.includes("Cannot read properties of undefined (reading 'isProxied')")) {
        window.location.reload()
        return null
      }
      return event
    },
  })
}

declare global {
  interface Window {
    rootStore: RootStore
    Offline: any
    Ionic: any
    token: any
    firebase: any
  }
}

const oldAppStore = window.localStorage.getItem('app')
if (oldAppStore) {
  window.localStorage.clear()
  window.localStorage.setItem('AppStore', oldAppStore)
}

if (Capacitor.getPlatform() != 'web') {
  const options = {
    style: Style.Light,
  }
  StatusBar.setStyle(options)
}

configurePersistable(
  {
    storage: window.localStorage,
  },
  { fireImmediately: true }
)

const rootStore = new RootStore()
window.rootStore = rootStore
rootStore.loadData('app started')
rootStore.processActions()

const stores = {
  rootStore: rootStore,
  appStore: rootStore.appStore,
  authStore: rootStore.authStore,
  itemsStore: rootStore.itemsStore,
  listItemsStore: rootStore.listItemsStore,
  categoriesStore: rootStore.categoriesStore,
  boardsStore: rootStore.boardsStore,
  boardInvitationsStore: rootStore.boardInvitationsStore,
  userStore: rootStore.userStore,
  recipesStore: rootStore.recipesStore,
  recipeCategoriesStore: rootStore.recipeCategoriesStore,
  mealCategoriesStore: rootStore.mealCategoriesStore,
  mealsStore: rootStore.mealsStore,
  botSessionsStore: rootStore.botSessionsStore,
}

window.Offline.options = {
  checkOnLoad: false,
  interceptRequests: false,
  requests: false,
  reconnect: true,
  game: false,
  checks: {
    xhr: { url: process.env.REACT_APP_API_URL + '/api/hello/helloyou' },
  },
}

window.rootStore = rootStore
promiseFinally.shim()

ReactDOM.render(
  <Provider {...stores}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>,
  document.getElementById('root') as HTMLElement
)

defineCustomElements(window)

if (Capacitor.getPlatform() === 'web') {
  serviceWorkerRegistration.register()
  reportWebVitals()
}

console.log('v1.3.8.4')
