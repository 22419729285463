import React from 'react'
import { inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import GuestUserPageContent from './GuestUserPageContent'

interface Props {
  appStore?: AppStore
  history: any
}

@inject('appStore')
export default class GuestUserPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/guestuser')
  }

  render() {
    return <GuestUserPageContent />
  }
}
