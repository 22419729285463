import { observable, action, reaction, computed, makeObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { RootStore } from './RootStore'
import { ThemeGenVM } from '../theme-gen/ThemeGenVM'
import { SideMenuVM } from '../side-menu/SideMenuVM'
import { Capacitor } from '@capacitor/core'
import { ThemesListVM } from '../themes/view-models/ThemesListVM'
import { SignalRServiceV2 } from '../signal-r/SignalRServiceV2'
import { FirebaseService } from '../firebase/FirebaseService'
import { StatusBar, Style } from '@capacitor/status-bar'
import { App, AppState } from '@capacitor/app'
import { SplashScreen } from '@capacitor/splash-screen'
import Environment from '../environment/Environment'

export class AppStore {
  public rootStore: RootStore
  @observable private activeModalHeaderHeight: number = 0
  private listeningToAppStateChange: boolean = false
  @observable private bottomNavBarHeight: number = 56
  @observable private appHeaderHeight: number = 56
  @observable public splashScreenHidden: boolean = false
  private signalRService: SignalRServiceV2
  private statusBarColor: string = 'light'
  private backJustPressedTO: NodeJS.Timer
  private reloadInterval: NodeJS.Timeout

  constructor(rootStore: RootStore) {
    makeObservable(this)
    makePersistable(this, { name: 'AppStore', properties: ['token', 'theme'] }).then((st) => {
      let isHydrated = false
      if (process.env.NODE_ENV === 'test') isHydrated = true
      if (st?.isHydrated) isHydrated = true
      if (isHydrated) this.onHydrationCompleted()
    })
    this.rootStore = rootStore
    this.signalRService = new SignalRServiceV2(this)
    this.sideMenuVM = new SideMenuVM(this.rootStore)
    this.themesListVM = new ThemesListVM(this.rootStore)
    this.firebaseSvc = new FirebaseService(this.rootStore)
    reaction(
      () => this.token,
      (token) => {
        window.token = token
        setTimeout(() => this.startReloadIfSeeding(), 2000)
      }
    )
    reaction(
      () => this.rootStore.boardsStore.currentBoardId,
      () => {
        setTimeout(() => this.startReloadIfSeeding(), 2000)
      }
    )
    if (this.theme) document.body.classList.add(this.theme)
  }

  @observable public token: string = ''
  @observable public isHydrated: boolean = false
  @observable public theme = ''
  @observable public currentRoute = ''
  @observable public isMenuOpen: boolean = false
  @observable public isSplitPaneVisible: boolean = false
  @observable public bodyHeight: number = 0
  @observable public currentTabIndex: number
  @observable public themeGenVM: ThemeGenVM = new ThemeGenVM()
  @observable public themesListVM: ThemesListVM = null
  @observable public sideMenuVM: SideMenuVM = null
  @observable public hasAvailableAppUpdate: boolean = false
  public sideMenu: any = null
  public history: any = null
  @observable public pastRoutes: Array<string> = []
  @observable public loadingData: string = ''
  public firebaseSvc: FirebaseService
  public backJustPressed: boolean = false
  @observable public activeModalId: string = ''

  public listenToAppStateChange() {
    if (process.env.NODE_ENV === 'test') return
    if (this.listeningToAppStateChange) return
    // if (Capacitor.getPlatform() === 'web') {
    //   this.listeningToAppStateChange = true
    //   return
    // }
    setTimeout(
      () =>
        App.addListener('appStateChange', (state: AppState) => {
          if (!this.isLoggedIn) return
          if (state.isActive) {
            this.rootStore.loadData('app restored')
            this.themeGenVM.listenToSystemThemeChange(true)
          } else {
            this.rootStore.handleAppBackgrounded()
          }
        }),
      1000
    )
    this.listeningToAppStateChange = true
  }

  public listenForSignalRUpdate(updateType: string, callback: (e) => void, replace: boolean = false) {
    this.signalRService.attachNewListener(updateType, callback, replace)
  }

  private startReloadIfSeeding() {
    if (this.reloadInterval) clearInterval(this.reloadInterval)
    this.reloadInterval = setInterval(() => this.reloadIfSeeding(), 4000)
  }

  private reloadIfSeeding() {
    if (!this.isSeeding) {
      clearInterval(this.reloadInterval)
      return
    }
    if (!this.isLoggedIn) {
      clearInterval(this.reloadInterval)
      return
    }
    this.rootStore.loadData('reloading because seeding')
  }

  @action
  public setBodyHeight(val: number) {
    this.bodyHeight = val
  }

  @action
  public setLoadingData(val: string) {
    this.loadingData = val
  }

  @action
  public navigateTo(val: string, replace: boolean = false) {
    this.setCurrentRoute(val)
    this.setMenuOpen(false)
    this.firebaseSvc.logRoutingEvent(this.currentRoute)
    if (replace) {
      this.history.replace(val)
      return
    }
    let doDelay = true
    if (this.currentRoute === '/itemsselect') doDelay = false
    if (doDelay) {
      setTimeout(() => this.history.push(val), 1)
      return
    }
    this.history.push(val)
  }

  @action
  public setCurrentTab(idx: number): void {
    this.currentTabIndex = idx
    if (idx === 0) this.navigateTo('/')
    if (idx === 1) this.navigateTo('/mealslist')
    if (idx === 2) this.navigateTo('/recipeslist')
  }

  public setHistory(e) {
    this.history = e
  }

  public setSideMenu(e) {
    this.sideMenu = e
  }

  public closeSideMenu() {
    this.sideMenu.close()
  }

  public setRootStore(root) {
    this.rootStore = root
    this.sideMenuVM = new SideMenuVM(this.rootStore)
    this.themeGenVM.setRootStore(this.rootStore)
    this.themesListVM.setRootStore(this.rootStore)
  }

  public async handleAndroidForcedSystemThemeChange() {
    this.themeGenVM.handleAndroidForcedSystemThemeChange()
    await this.rootStore.appStore.checkNativeStatusBarColor()
  }

  @action
  public setMenuOpen(isOpen) {
    this.isMenuOpen = isOpen
  }

  @action
  public setSplitPaneVisible(isVisible) {
    this.isSplitPaneVisible = isVisible
    if (isVisible) {
      document.getElementsByTagName('html')[0].className += ' split-pane-visible'
      return
    }
    let className = document.getElementsByTagName('html')[0].className
    className = className.replace(' split-pane-visible', '')
    document.getElementsByTagName('html')[0].className = className
  }

  @computed
  public get isLoggedIn() {
    if (!this.token) return false
    if (this.token === '') return false
    return true
  }

  @computed
  public get showAppHeader() {
    if (this.isSplitPaneVisible) return true
    if (this.currentRoute === 'login') return false
    if (this.currentRoute === '/itemsselect') return false
    if (this.currentRoute === '/maintabs/itemnew') return false
    if (this.currentRoute === '/maintabs/listitemedit') return false
    if (this.currentRoute === '/accountsettings') return false
    if (this.currentRoute.includes('/itemedit')) return false
    if (this.currentRoute.includes('/recipecategoryedit')) return false
    if (this.currentRoute.includes('/boardsettings')) return false
    if (this.currentRoute.includes('/themegen')) return false
    if (this.currentRoute.includes('/themeslist')) return false
    if (this.currentRoute.includes('/recipeedit')) return false
    if (this.currentRoute.includes('/categoryedit')) return false
    if (this.currentRoute.includes('/mealcategoryedit')) return false
    if (this.currentRoute.includes('/mealcategorieslist')) return false
    if (this.currentRoute.includes('/mealedit')) return false
    if (this.currentRoute.includes('/itemsselect')) return false
    if (this.currentRoute.includes('/categorieslist')) return false
    if (this.currentRoute.includes('/recipecategorieslist')) return false
    if (this.currentRoute.includes('/settings')) return false
    if (this.currentRoute.includes('/recipeimport')) return false
    if (this.currentRoute.includes('/botsessionedit')) return false
    if (this.currentRoute.includes('/botsessionnew')) return false
    if (this.currentRoute.includes('/botsessionslist')) return false
    if (this.currentRoute.includes('/guestuser')) return false
    return true
  }

  @computed
  public get pageTitle() {
    if (this.currentRoute === 'login') return 'Grocery Board'.toUpperCase()
    if (this.currentRoute === '/itemsselect') return 'Add Item'.toUpperCase()
    if (this.currentRoute === '/') return 'Shopping List'.toUpperCase()
    if (this.currentRoute === '/maintabs/itemnew') return 'New Item'.toUpperCase()
    if (this.currentRoute === '/maintabs/tab2') return 'Meals'.toUpperCase()
    if (this.currentRoute === '/maintabs/tab3') return 'Cookbook'.toUpperCase()
    if (this.currentRoute === '/maintabs/listitemedit') return 'Edit List Item'.toUpperCase()
    return this.currentRoute.toUpperCase()
  }

  @computed
  public get showBackButton() {
    if (this.currentRoute === '/itemsselect') return true
    if (this.currentRoute === '/maintabs/itemnew') return true
    if (this.currentRoute === '/maintabs/listitemedit') return true
    if (this.currentRoute === '/accountsettings') return true
    if (this.currentRoute === '/themegen') return false
    if (this.currentRoute === '/themeslist') return false
    return false
  }

  public getSection() {
    let section = 'shopping-list'
    if (this.currentRoute === '/itemslistmain') section = 'items'
    if (this.currentRoute === '/categorieslist') section = 'categories'
    if (this.currentRoute === '/mealcategorieslist') section = 'mealcategories'
    if (this.currentRoute === '/mealcategoryedit') section = 'mealcategories'
    if (this.currentRoute === '/mealslist') section = 'meals'
    if (this.currentRoute === '/mealedit') section = 'meals'
    if (this.currentRoute === '/recipeslist') section = 'recipes'
    if (this.currentRoute === '/recipeedit') section = 'recipes'
    if (this.currentRoute === '/recipeimport') section = 'recipes'
    if (this.currentRoute === '/recipecategorieslist') section = 'recipecategories'
    if (this.currentRoute === '/guestuser') section = 'settings'
    return section
  }

  public isCurrentSection(section: string) {
    return this.getSection() === section
  }

  @action
  public setToken(token) {
    this.token = token
  }

  @action
  public setHasUpdate(val: boolean) {
    // if (this.rootStore.appStore.isWeb) val = true // for testing
    this.hasAvailableAppUpdate = val
  }

  @computed
  public get isSeeding() {
    return !this.rootStore.boardsStore?.currentBoard?.IsSeeded
  }

  @action
  public clearData() {
    this.token = null
    console.log('logged out')
  }

  @action
  public setCurrentRoute(routeName: string) {
    routeName = routeName.toLowerCase()
    if (routeName === '/register/') routeName = '/register'
    this.logPastRoute(routeName)
    if (routeName === this.currentRoute) return
    this.currentRoute = routeName
    this.computeCurrentTab()
    if (this.lastRoute === routeName) return
    if (this.currentRoute === '/') this.pastRoutes = []
  }

  @action
  private logPastRoute(routeName) {
    if (this.pastRoutes.length > 0 && this.pastRoutes[this.pastRoutes.length - 1] === routeName) return
    this.pastRoutes.push(routeName)
  }

  @computed
  public get lastRoute(): string {
    if (this.pastRoutes.length === 0) return null
    if (this.pastRoutes.length === 1) return null
    return this.pastRoutes[this.pastRoutes.length - 2]
  }

  @action
  public computeCurrentTab() {
    const section = this.getSection()
    if (section === 'shopping-list') this.currentTabIndex = 0
    if (section === 'meals') this.currentTabIndex = 1
    if (section === 'recipes') this.currentTabIndex = 2
  }

  @action
  public computeBottomNavBarHeight() {
    this.bottomNavBarHeight = this.getBottomNavBarHeight()
  }

  @action
  public computeAppHeaderHeight() {
    this.appHeaderHeight = this.getAppHeaderHeight()
  }

  private getAppHeaderHeight(): number {
    try {
      const computed = window.getComputedStyle(document.getElementById('HeaderToolbar')).height
      if (computed) {
        // console.log('App Header Height: ' + computed)
        if (computed === 'auto') return 56
        return Number(computed.replace('px', ''))
      }
    } catch (ex) {}
    return 56
  }

  private getBottomNavBarHeight(): number {
    try {
      const computed = window.getComputedStyle(document.getElementById('BottomTabs')).height
      if (computed) {
        // console.log('Bottom Tabs Height: ' + computed)
        if (computed === 'auto') return 56
        return Number(computed.replace('px', ''))
      }
    } catch (ex) {}
    return 56
  }

  @action
  public handleGoBack(history = null) {
    let debug = false
    if (process.env.NODE_ENV === 'production') debug = false
    if (debug) console.log('Current: ' + this.currentRoute)
    if (debug) console.log('Past: ' + this.lastRoute)

    if (!history) history = this.history
    if (!this.currentRoute) this.setCurrentRoute(this.history.location.pathname)
    let newRoute = '/'
    if (this.currentRoute === '/maintabs/itemnew') newRoute = '/itemsselect'
    if (this.currentRoute.includes('/itemedit') && this.lastRoute === '/itemsselect') newRoute = '/itemsselect'
    if (this.currentRoute.includes('/itemedit') && this.lastRoute === '/itemslist') newRoute = '/itemslist'
    if (this.currentRoute === '/categorieslist') {
      if (this.lastRoute === '/itemslist') newRoute = '/itemslist'
      if (this.lastRoute === '/itemsselect') newRoute = '/itemsselect'
      if (this.pastRoutes[1] === '/itemslist') newRoute = '/itemslist'
      if (this.pastRoutes[1] === '/itemsselect') newRoute = '/itemsselect'
    }
    if (this.currentRoute.includes('/recipecategoryedit')) newRoute = '/recipecategorieslist'
    if (this.currentRoute.includes('/recipecategorieslist')) newRoute = '/recipeslist'
    if (this.currentRoute.includes('/mealcategorieslist')) newRoute = '/mealslist'
    if (this.currentRoute.includes('/mealcategoryedit')) newRoute = '/mealcategorieslist'
    if (this.currentRoute.includes('/categoryedit')) newRoute = '/categorieslist'
    if (this.currentRoute.includes('/mealedit')) newRoute = '/mealslist'
    if (this.currentRoute.includes('/recipeedit')) newRoute = '/recipeslist'
    if (this.currentRoute.includes('/recipeedit/new')) {
      {
        if (!this.rootStore.featuresStore.hasRecipeBotsScreenFeature) newRoute = '/recipeslist'
        else newRoute = '/botsessionslist'
      }
    }
    if (this.currentRoute.includes('/accountsettings')) newRoute = '/settings'
    if (this.currentRoute.includes('/boardsettings')) newRoute = '/settings'
    if (this.currentRoute.includes('/recipeimport')) newRoute = '/recipeslist'
    if (this.currentRoute.includes('/themeslist')) newRoute = '/settings'
    if (this.currentRoute.includes('/botsessionslist')) newRoute = '/recipeslist'
    if (this.currentRoute.includes('/botsessionedit')) {
      if (!this.rootStore.featuresStore.hasRecipeBotsScreenFeature) newRoute = '/recipeslist'
      else newRoute = '/botsessionslist'
    }
    if (this.currentRoute.includes('/botsessionnew')) newRoute = '/botsessionslist'
    if (this.currentRoute === '/register') newRoute = '/login'
    if (this.currentRoute === '/forgot-password') newRoute = '/login'
    if (this.currentRoute === '/guestuser') newRoute = '/settings'

    if (this.currentRoute === '/itemsselect') {
      const listVM = this.rootStore.itemsStore.listVM
      if (listVM.hasFilter) {
        listVM.clearFilter()
        return
      }
      const itemNewVM = listVM.itemNewVM
      if (itemNewVM && itemNewVM.shown) {
        itemNewVM.cancel()
        return
      }
    }

    if (this.currentRoute.includes('/mealedit')) {
      const editVM = this.rootStore.mealsStore.editVM
      const itemsList = editVM.itemsList
      if (itemsList) {
        const itemNewVM = itemsList.itemNewVM
        if (itemNewVM) {
          const addingNewItem = itemNewVM.shown
          if (addingNewItem) {
            itemsList.itemNewVM.cancel()
            return
          }
        }
        const selectingItems = itemsList.shown
        if (selectingItems) {
          if (itemsList.hasFilter) {
            setTimeout(() => itemsList.clearFilter(), 1000)
            return
          }
          itemsList.cancel()
          return
        }
      }
    }

    if (this.currentRoute.includes('/recipeedit')) {
      const editVM = this.rootStore.recipesStore.editVM
      if (editVM.isEditingItem) {
        editVM.hideItemEditModal()
        return
      }
    }

    if (this.pastRoutes.length >= 1) {
      if (debug) console.log('before pop')
      if (debug) console.log(this.pastRoutes.slice())
      if (this.lastRoute && this.lastRoute.includes('/mealedit/') && !this.currentRoute.includes('/mealedit/')) {
        newRoute = this.lastRoute
      }
      this.pastRoutes.pop()
    }

    if (!this.isWeb) {
      if (this.currentRoute === '/' || this.currentRoute === '/login') {
        App.exitApp()
      }
    }

    this.setBackJustPressed()

    if (debug) console.log('after pop')
    if (debug) console.log(this.lastRoute)
    if (debug) console.log(this.pastRoutes.slice())

    history.replace(newRoute)
  }

  @action
  public setBackJustPressed() {
    this.backJustPressed = true
    if (this.backJustPressedTO) clearTimeout(this.backJustPressedTO)
    this.backJustPressedTO = setTimeout(() => this.clearBackJustPressed(), 1000)
  }

  @action
  public clearBackJustPressed() {
    this.backJustPressed = false
  }

  @computed
  public get isHybridIos() {
    const classNames = document.getElementsByTagName('html')[0].className
    if (classNames.indexOf('plt-hybrid') === -1) return false
    if (classNames.indexOf('plt-ios') === -1) return false
    return true
  }

  @computed
  public get isIos() {
    const classNames = document.getElementsByTagName('html')[0].className
    if (classNames.indexOf('plt-ios') === -1) return false
    return true
  }

  @computed
  public get isIosNative() {
    return this.isIos && this.isNative
  }

  @computed
  public get isNative() {
    const classNames = document.getElementsByTagName('html')[0].className
    if (classNames.indexOf('plt-capacitor') === -1) return false
    return true
  }

  @computed
  public get isAndroid() {
    const classNames = document.getElementsByTagName('html')[0].className
    if (classNames.indexOf('plt-android') === -1) return false
    return true
  }

  @computed
  public get isAndroidNative() {
    const classNames = document.getElementsByTagName('html')[0].className
    if (classNames.indexOf('plt-android') === -1) return false
    if (classNames.indexOf('plt-hybrid') === -1) return false
    return true
  }

  @computed
  public get isWeb() {
    if (Capacitor.getPlatform() === 'web') return true
    return false
  }

  @computed
  public get isSimulatedNative() {
    if (Environment.isDevMode) return true
    if (Capacitor.getPlatform() === 'web') return true
    return false
  }

  @computed
  public get isWindows() {
    const classNames = document.getElementsByTagName('html')[0].classList
    if (classNames.contains('plt-windows')) return true
    return false
  }

  @action
  public async hideSplashScreen() {
    if (this.splashScreenHidden) return
    this.splashScreenHidden = true
    if (Capacitor.getPlatform() === 'web') return
    console.log('splash screen hiding')
    await Promise.all([SplashScreen.hide(), this.checkNativeStatusBarColor()])
    setTimeout(() => this.checkNativeStatusBarColor(), 500)
    setTimeout(() => this.checkNativeStatusBarColor(), 1000)
    setTimeout(() => this.checkNativeStatusBarColor(), 2000)
    console.log('splash screen hidden')
  }

  @action
  public async checkNativeStatusBarColor() {
    if (Capacitor.getPlatform() === 'web') return
    if (!this.splashScreenHidden) return
    // if (!this.isLoggedIn) return
    let statusBarColor = 'light'
    if (this.isLoggedIn) statusBarColor = 'dark'
    const options = {
      style: statusBarColor === 'dark' ? Style.Dark : Style.Light,
    }
    await StatusBar.setStyle(options)
    this.statusBarColor = statusBarColor
    if (this.isIos) return
    let color = this.themeGenVM.primaryColor
    if (!this.isLoggedIn) color = this.themeGenVM.mediumColor
    await StatusBar.setBackgroundColor({
      color: color,
    })
  }

  @computed
  private get pageOffsetHeight() {
    if (this.currentRoute === '/recipeslist') {
      if (this.isIos) return 0
      return 0
    }
    if (this.currentRoute.indexOf('/recipeedit') > -1) {
      if (this.isIos) return 83 - 12
      return 83
    }
    if (this.currentRoute === '/itemslist') {
      if (this.isIos) return 44
      return 56
    }
    if (this.currentRoute === '/itemsselect') {
      if (this.isSplitPaneVisible) {
        if (this.isIos) return 45
        return 57
      }
      if (this.isIos) return 55
      return 67
    }
    if (this.currentRoute === '/mealslist') {
      return 0
    }
    if (this.currentRoute === '/') {
      if (this.isIos) return 0
      return 0
    }
    if (this.isIos) return 0
    return 10
  }

  @computed
  public get listHeight(): number {
    const BOTTOM_NAV_BAR_HEIGHT = this.bottomNavBarHeight
    const APP_HEADER_HEIGHT = this.appHeaderHeight
    // const IOS_HYBRID_TOP_PADDING = 20
    const PAGE_TOOLBAR_HEIGHT = this.isHybridIos && !this.isSplitPaneVisible ? 56 : 46
    let height = this.bodyHeight
    height = height - PAGE_TOOLBAR_HEIGHT
    height = height - this.pageOffsetHeight
    if (!this.isSplitPaneVisible && this.showAppHeader) height = height - BOTTOM_NAV_BAR_HEIGHT
    if (this.showAppHeader) height = height - APP_HEADER_HEIGHT
    // if (this.isHybridIos) height = height - IOS_HYBRID_TOP_PADDING
    if (this.isWindows) height = height + 10
    if (this.isIos) height = height + 10
    return height
  }

  @computed
  public get listHeightForModal(): number {
    const modal = document.getElementById(this.activeModalId)
    if (!modal) {
      if (this.isSplitPaneVisible) return 0
      return 0
    }
    const wrapper = document.getElementById('ion-react-wrapper')
    let height = wrapper?.clientHeight
    if (!height) height = this.listHeight
    height = height - this.activeModalHeaderHeight
    if (this.isHybridIos) height = height - 20
    return height
  }

  @computed
  public get listHeightPx(): string {
    return this.listHeight.toString() + 'px'
  }

  @computed
  public get listHeightForModalPx(): string {
    return this.listHeightForModal.toString() + 'px'
  }

  @action
  public setActiveModal(val: string, modalHeaderHeight: number) {
    this.activeModalId = ''
    this.activeModalId = val
    this.activeModalHeaderHeight = modalHeaderHeight
  }

  @computed
  public get fabOffset(): string {
    if (this.isHybridIos && !this.isSplitPaneVisible && this.showAppHeader)
      return -(64 + this.bottomNavBarHeight) + 'px'
    if (!this.isSplitPaneVisible && this.showAppHeader) return '-120px'
    return '0px'
  }

  @action
  public onHydrationCompleted() {
    this.signalRService.listenToServer('APP HYDRATED')
    this.isHydrated = true
    this.themeGenVM.onHydrationCompleted()
    this.listenToAppStateChange()
    this.computeIsWindows()
    this.firebaseSvc.setUserInfoFromSaved()
    this.checkNativeStatusBarColor()
  }

  private computeIsWindows() {
    let isWindows = false
    if (navigator.platform.indexOf('Win') > -1) isWindows = true
    if (this.isHybridIos) isWindows = false
    if (this.isIos) isWindows = false
    if (this.isAndroid) isWindows = false
    document.getElementsByTagName('html')[0].classList.remove('plt-windows')
    if (!isWindows) return
    document.getElementsByTagName('html')[0].className += ' plt-windows'
  }

  public applyScrollStyles(e: any) {
    if (this.isWindows) this.doApplyWindowsStyles(e)
    if (this.isAndroid && this.isSimulatedNative) this.doApplyNonWindowsStyles(e)
    if (this.isIos && this.isSimulatedNative) this.doApplyNonWindowsStyles(e)
    setTimeout(() => this.applyScrollStyles(e), 1)
  }

  private doApplyWindowsStyles(e: HTMLIonContentElement) {
    this.doApplyScrollStyles(e)
  }

  private doApplyNonWindowsStyles(e: HTMLIonContentElement) {
    this.doHideScrollbar(e)
    setTimeout(() => this.doHideScrollbar(e), 300)
  }

  private doApplyScrollStyles(e: any) {
    if (!e) return
    const styles = document.createElement('style')
    styles.textContent = `
    ::-webkit-scrollbar {
      width: 9px;
      height: 18px;
    }
  
    ::-webkit-scrollbar-thumb {
      height: 6px;
      border: 2px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      -webkit-border-radius: 7px;
      background-color: rgba(0, 0, 0, .4);
      -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
  
    ::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
  
    ::-webkit-scrollbar-corner {
      background-color: transparent;
    }
      `
    if (e.shadowRoot) e.shadowRoot.appendChild(styles)
    else if (e.rootNode) e.rootNode.appendChild(styles)
  }

  private doHideScrollbar(e: any) {
    if (!e) return
    const styles = document.createElement('style')
    styles.textContent = `
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  
    ::-webkit-scrollbar-thumb {
      height: 0;
    }
  
    ::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
  
    ::-webkit-scrollbar-corner {
      background-color: transparent;
    }
      `
    if (e.shadowRoot) e.shadowRoot.appendChild(styles)
    else if (e.rootNode) e.rootNode.appendChild(styles)
  }
}
