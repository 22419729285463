import React from 'react'
import {
  IonList,
  IonItem,
  IonButton,
  IonModal,
  IonLabel,
  IonInput,
  IonTitle,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonFab,
  IonFabButton,
  IonSelectOption,
  IonSelect,
  IonGrid,
  IonRow,
  IonCol,
  IonRippleEffect,
  IonCheckbox,
} from '@ionic/react'
import { observer } from 'mobx-react'
import { add, remove, close, checkmarkSharp } from 'ionicons/icons'
import CategoryNewModal from '../../../items-select/views/CategoryNewModal'
import BackIcon from 'src/shared/BackIcon'
import { ItemEditModalVM } from '../../view-models/edit/ItemEditModalVM'
import { TextUtils } from '../../../shared/TextUtils'

interface Props {
  vm: ItemEditModalVM
}

const ItemEditModal: React.FC<Props> = ({ vm }) => {
  const handleSave = (e) => {
    e.preventDefault()
    vm.save()
  }

  const renderCategory = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Category</IonLabel>
        <IonSelect interface='popover' value={vm.categoryGuid} onIonChange={(e: any) => vm.setCategory(e.target.value)}>
          {vm.categories.map((e) => (
            <IonSelectOption key={e.CategoryGuid} value={e.CategoryGuid}>
              {e.Name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderName = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Name</IonLabel>
        <IonInput
          type='text'
          autocapitalize='word'
          autofocus
          value={vm.name}
          onIonChange={(e: any) => vm.setName(e.target.value)}
          ref={(e) => TextUtils.disableAutoComplete(e)}
        />
      </IonItem>
    )
  }

  return (
    <>
      <IonModal id='ItemNewModal' isOpen={vm.shown} showBackdrop={vm.backdropShown} onDidDismiss={() => vm.hide()}>
        <IonHeader id='ItemNewModal_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => vm.cancel()}>
                <BackIcon />
              </IonButton>
            </IonButtons>
            <IonTitle>Edit Item</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={() => vm.cancel()}>
                <IonIcon icon={close} />
              </IonButton>
              <IonButton onClick={() => vm.save()} disabled={!vm.isValid}>
                <IonIcon icon={checkmarkSharp} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form onSubmit={(e) => handleSave(e)}>
            <IonList>
              {renderName()}
              {renderCategory()}
            </IonList>
          </form>
        </IonContent>
        <IonFab vertical='bottom' horizontal='end'>
          <IonFabButton
            disabled={!vm.isValid}
            color='tertiary'
            onClick={() => vm.save()}
            style={{
              marginTop: '-60px',
              marginLeft: '-60px',
            }}
          >
            <IonIcon icon={checkmarkSharp} />
          </IonFabButton>
        </IonFab>
      </IonModal>
      <CategoryNewModal categoryNewVM={vm.categoryNewVM} />
    </>
  )
}

export default observer(ItemEditModal)
