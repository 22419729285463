import { RootStore } from 'src/stores/RootStore'
import { computed, action, makeObservable } from 'mobx'
import { Recipe } from 'src/recipes/aggregate/Recipe'
import { Category } from 'src/recipes/aggregate/Category'
import { RecipeSelectListVM } from './RecipeSelectListVM'
import { RowVM } from '@elexient/elexiapp.bits.shared'
import Environment from '../../../environment/Environment'

export class RecipeRowVM extends RowVM<RootStore, RecipeSelectListVM, Recipe> {
  public get height(): number {
    if (this.isHidden) return 0
    return Environment.listRowHeight
  }

  public get recipe() {
    return this.record
  }

  public get keyword(): string {
    return this.name + this.recipeCategoryNames
  }

  @action
  public click() {
    this.rootStore.appStore.history.push('/recipeedit/' + this.recipe.RecipeGuid)
  }

  @computed
  public get name(): string {
    return this.recipe.Name
  }

  @computed
  public get recipeGuid(): string {
    return this.recipe.RecipeGuid
  }

  @action
  public selectRecipe() {
    this.listVM.hide()
    this.listVM.addRecipe(this.recipeGuid)
  }

  @computed
  public get recipeCategoryNames(): string[] {
    const names = []
    this.recipe.Categories.filter((e) => !e.IsDeleted).forEach((e) => {
      const foundCat = this.rootStore.recipeCategoriesStore.get(e.RecipeCategoryGuid)
      if (foundCat) names.push(foundCat.Name)
    })
    if (names.length === 0) names.push('(No Category)')
    return names
  }

  @computed
  public get categories(): Array<Category> {
    return this.recipe.Categories
  }

  public hasCategory(recipeCategoryGuid: string): boolean {
    return this.recipe.Categories.findIndex((e) => e.RecipeCategoryGuid === recipeCategoryGuid) !== -1
  }
}
