import { inject, observer, useLocalObservable } from 'mobx-react'
import React from 'react'
import { GuestUserToastVM } from '../../view-models/GuestUserToastVM'
import { IonBackdrop, IonToast } from '@ionic/react'
import { personOutline } from 'ionicons/icons'
import { RootStore } from '../../../stores/RootStore'

interface Props {
  rootStore?: RootStore
}

const GuestUserToast: React.FC<Props> = ({ rootStore }) => {
  const vm = useLocalObservable(() => new GuestUserToastVM(rootStore))
  if (!vm.shown) return null

  return (
    <>
      <IonBackdrop visible={vm.shown} tappable onIonBackdropTap={() => vm.onDismiss()} style={{ opacity: 0.5 }} />
      <IonToast
        id='GuestUserToast'
        cssClass='guest-user-toast'
        isOpen={vm.shown}
        header='Welcome Guest User!'
        layout='stacked'
        onDidDismiss={() => vm.onDismiss()}
        message={vm.message}
        position='bottom'
        animated={true}
        icon={personOutline}
        buttons={[
          {
            text: 'Dismiss',
            role: 'cancel',
          },
          {
            text: 'Register Now',
            handler: () => vm.goToRegister(),
          },
        ]}
      />
    </>
  )
}

export default inject('rootStore')(observer(GuestUserToast))
