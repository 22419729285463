import { RootStore } from '../stores/RootStore'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { Capacitor } from '@capacitor/core'
import Environment from '../environment/Environment'

export class FirebaseService {
  private rootStore: RootStore
  private fbInitialized: boolean

  private firebaseConfig = {
    apiKey: 'AIzaSyAEKHI0NPmawFwKk7HfdS415atx4M3t4gc',
    authDomain: 'groceryboard.firebaseapp.com',
    databaseURL: 'https://groceryboard.firebaseio.com',
    projectId: 'groceryboard',
    storageBucket: 'groceryboard.appspot.com',
    messagingSenderId: '151188122140',
    appId: '1:151188122140:web:6a7ac5a2d2fd9f1b0e8dd9',
    measurementId: 'G-5NETPRME31',
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    // this.initFirebaseForWeb()
    this.initFirebase()
    // const firebaseConfig = {
    //   apiKey: 'AIzaSyAEKHI0NPmawFwKk7HfdS415atx4M3t4gc',
    //   authDomain: 'groceryboard.firebaseapp.com',
    //   databaseURL: 'https://groceryboard.firebaseio.com',
    //   projectId: 'groceryboard',
    //   storageBucket: 'groceryboard.appspot.com',
    //   messagingSenderId: '151188122140',
    //   appId: '1:151188122140:web:6a7ac5a2d2fd9f1b0e8dd9',
    //   measurementId: 'G-5NETPRME31',
    // }
    // firebase.initializeApp(firebaseConfig)
    // console.log('initialized firebase')
  }

  private initFirebaseForWeb() {
    if (this.rootStore.appStore.isIos) return
    if (this.rootStore.appStore.isAndroid) return
    if (Environment.isDevMode) return
    const script1 = document.createElement('script')
    script1.src = '/__/firebase/8.2.4/firebase-app.js'
    const script2 = document.createElement('script')
    script2.src = '/__/firebase/8.2.4/firebase-analytics.js'
    const script3 = document.createElement('script')
    script3.src = '/__/firebase/init.js'
    document.body.appendChild(script1)
    document.body.appendChild(script2)
    document.body.appendChild(script3)
    this.logEvent('app_accessed')
    console.log(window.navigator.userAgent)
    if (!window.firebase) console.log('firebase not initialized')
  }

  private async initFirebase(attempts: number = 1) {
    if (process.env.NODE_ENV === 'test') return
    if (Environment.isDevMode) {
      console.log('not enabling google analytics')
      return
    }
    if (attempts > 10) return
    // if (typeof this.fb.initializeFirebase !== 'function') {
    //   setTimeout(() => this.initFirebase(attempts++), 1000)
    //   return
    // }
    if (Capacitor.getPlatform() === 'web') await FirebaseAnalytics.initializeFirebase(this.firebaseConfig)
    await FirebaseAnalytics.setCollectionEnabled({ enabled: true })
    this.fbInitialized = true
    console.log('initialized firebase')
  }

  public setUserInfo(userId, email, name) {
    if (!this.fbInitialized) return
    FirebaseAnalytics.setUserId({ userId: userId })
    FirebaseAnalytics.setUserProperty({
      name: 'name',
      value: name,
    })
    FirebaseAnalytics.setUserProperty({
      name: 'email',
      value: email,
    })
  }

  public setUserInfoFromSaved() {
    if (!this.rootStore.appStore.isLoggedIn) return
    if (!this.rootStore.userStore.user) return
    if (!this.rootStore.userStore.user.IdentityId) return
    this.setUserInfo(
      this.rootStore.userStore.user.IdentityId,
      this.rootStore.userStore.user.EmailAddress,
      this.rootStore.userStore.user.Name
    )
  }

  public logRoutingEvent(route: string) {
    if (route === '/' && !this.fbInitialized) return
    if (route === '/') this.logScreenView('shopping_list')
    if (route === '/recipeslist') this.logScreenView('recipes_list')
    if (route === '/mealslist') this.logScreenView('meals_list')
  }

  public logScreenView(name) {
    if (this.rootStore.appStore.isWeb) {
      // console.log('logged screen view event as WEB: ' + name)
      this.logEvent('screen_view', { screen_name: name })
      return
    }
    if (!this.fbInitialized) return
    FirebaseAnalytics.setScreenName({
      screenName: name,
      nameOverride: name,
    })
    // console.log('logged screen view event: ' + name)
  }

  public logEvent(eventName: string, params?: any) {
    if (!this.fbInitialized) return
    if (params) FirebaseAnalytics.logEvent({ name: eventName, params: params })
    else FirebaseAnalytics.logEvent({ name: eventName, params: undefined })
  }
}
