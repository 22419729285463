export class GuestUserMessages {
  public static messageOptions = [
    "Don't lose your shopping list! Create a free account to back up your lists and keep them safe. It's quick and easy!",
    'Want to access your shopping list on all your devices? Register your free account to sync your lists and access them anywhere, anytime.',
    'Unlock more features and simplify your shopping! Sign up for a free account to access advanced features and organizational tools.',
    'Stay organized and never forget an item! Register your free account now to protect your data.',
    'Ready to save your shopping list? Create a free account in seconds!',
    'Invest in your grocery shopping! Registering your free account ensures your lists are saved and accessible across devices, saving you time and frustration.',
    'Thousands of shoppers are already simplifying their grocery trips. Create your free account now!',
    'Ready to streamline your grocery shopping? Register your free account to unlock personalized features.',
    'Level up your shopping game! Registering your free account to access on your phone.',
    'Tired of forgetting items at the store? Create a free account to save your shopping lists and never miss an ingredient again.',
    "We're glad you're using our app! Create a free account to keep your lists safe and unlock even more helpful features.",
    'Register your free account now to save your shopping lists, access them on multiple devices, and unlock helpful shopping tools.',
    'Want to make grocery shopping easier? Create your free account to save your lists and access them anytime, anywhere.',
    'Save time and money on your grocery trips! Create a free account to organize your shopping and plan ahead.',
  ]
}
