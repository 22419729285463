import { computed, makeObservable } from 'mobx'
import { Board } from '../../boards/aggregate/Board'
import { RootStore } from '../../stores/RootStore'

export class BoardRowVM {
  constructor(private rootStore: RootStore, private board: Board) {
    makeObservable(this)
  }

  @computed
  public get boardGuid() {
    return this.board.BoardGuid
  }

  @computed
  public get name() {
    let val = this.board.Name
    // if (this.isCurrentBoard) val += ' (Current Board)'
    return val
  }

  @computed
  public get isCurrentBoard() {
    return this.rootStore.boardsStore.currentBoard?.BoardGuid === this.board.BoardGuid
  }
}
