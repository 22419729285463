import { action, makeObservable, observable, runInAction } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import agent from '../../../Agent'

export class RegisterModalVM {
  private focused: boolean = false

  constructor(private rootStore: RootStore, private onClose: (success: boolean) => void) {
    makeObservable(this)
  }

  @observable public newPassword: string = ''
  @observable public confirmPassword: string = ''
  @observable public name: string = ''
  @observable public email: string = ''
  @observable public isProcessing: boolean = false
  @observable public errorMessages: string[] = []
  @observable public passwordShown: boolean = false
  @observable public toastShown: boolean = false

  @action
  public toggleShowPassword() {
    this.passwordShown = !this.passwordShown
  }

  @action
  public setNewPassword(password: string) {
    this.newPassword = password
    this.clearErrorMessages()
  }

  @action
  public setConfirmPassword(password: string) {
    this.confirmPassword = password
    this.clearErrorMessages()
  }

  @action
  public setName(name: string) {
    this.name = name
    this.clearErrorMessages()
  }

  @action
  public setEmail(email: string) {
    this.email = email
    this.clearErrorMessages()
  }

  @action
  private clearErrorMessages() {
    this.errorMessages = []
  }

  public setFocus(e: HTMLIonInputElement): void {
    if (this.focused) return
    if (!e) return
    this.focused = true
    e.setFocus()
    setTimeout(() => e.setFocus(), 100)
  }

  public setTabIndex(e: any, idx: number) {
    if (!e) return
    if (!e.nativeInput) {
      setTimeout(() => this.setTabIndex(e, idx), 100)
      return
    }
    e.nativeInput.tabIndex = idx
  }

  @action
  private showToast() {
    this.toastShown = true
  }

  @action
  private markAsProcessing() {
    this.isProcessing = true
  }

  @action
  private markAsNotProcessing() {
    this.isProcessing = false
  }

  @action
  private setErrorMessage(val: string) {
    this.errorMessages = [val]
  }

  public closeModal() {
    this.onClose(false)
  }

  @action
  public async handleSave() {
    this.clearErrorMessages()
    if (this.newPassword.length === 0) {
      this.setErrorMessage('Password cannot be empty')
      return
    }
    if (this.confirmPassword.length === 0) {
      this.setErrorMessage('Confirm Password cannot be empty')
      return
    }
    if (this.newPassword !== this.confirmPassword) {
      this.setErrorMessage('Passwords do not match')
      return
    }
    this.markAsProcessing()
    const success = await this.tryRegister({ email: this.email, name: this.name, password: this.newPassword })
    if (success) {
      this.showToast()
      await this.rootStore.userStore.loadUser()
      setTimeout(() => this.onClose(true), 3500)
    }
    this.markAsNotProcessing()
  }

  private async tryRegister(form) {
    try {
      const results: string[] = await agent.Users.registerGuestUser(form)
      if (results[0] !== 'Success') {
        results.forEach((e) => this.errorMessages.push(e))
        return false
      }
    } catch (e) {
      console.error(e)
      runInAction(() => {
        this.errorMessages = ['An error occurred. Please try again.']
      })
      return false
    }
    return true
  }
}
