import { computed } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { GuestUserMessages } from './GuestUserMessages'

export class GuestUserCardVM {
  constructor(private rootStore: RootStore) {}

  @computed
  public get shown() {
    return this.rootStore.userStore.isGuestUser
  }

  public goToRegister() {
    this.rootStore.appStore.navigateTo('/guestuser')
  }

  @computed
  public get message(): string {
    return GuestUserMessages.messageOptions[Math.floor(Math.random() * GuestUserMessages.messageOptions.length)]
  }
}
