import React from 'react'
import { IonButton, IonItem, IonText, IonLabel } from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { UserStore } from '../../store/UserStore'
import { AntDesign, EvilIcons } from 'react-web-vector-icons'
import { UserEditVM } from '../../view-models/UserEditVM'

interface Props {
  vm: UserEditVM
}

const GoogleLogin: React.FC<Props> = ({ vm }) => {
  const renderRemoveGoogleLogin = () => {
    if (!vm.hasGoogleLogin) return
    return (
      <IonText>
        <EvilIcons name='check' size={26} style={{ color: 'var(--ion-color-success)', marginRight: '5px' }} />
        <strong>Connected</strong>
      </IonText>
    )
  }

  const renderConnectGoogleLogin = () => {
    if (vm.hasGoogleLogin) return
    return <IonButton onClick={() => vm.connectGoogle()}>Connect</IonButton>
  }

  return (
    <IonItem lines={'inset'}>
      <IonLabel>
        <AntDesign name='google' size={20} />
        Google
      </IonLabel>
      {renderRemoveGoogleLogin()}
      {renderConnectGoogleLogin()}
    </IonItem>
  )
}

export default inject('userStore')(observer(GoogleLogin))
