import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import agent from '../../Agent'
import BoardInvitation from '../../board-invitations/aggregate/BoardInvitation'

export class RegisterVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
    this.loadBoardInvitation()
    this.loadGoogleLoginInfo()
    this.loadAppleLoginInfo()
  }

  @observable public name: string = ''
  @observable public email: string = ''
  @observable public newBoardName: string = ''
  @observable public password: string = ''
  @observable public passwordConfirm: string = ''
  @observable public errorMessages: Array<string> = []
  @observable public isProcessing = false
  @observable public boardInvitation: BoardInvitation = null
  @observable public acceptBoardInvitation: boolean = false

  @action
  public toggleAcceptBoardInvitation() {
    this.acceptBoardInvitation = !this.acceptBoardInvitation
  }

  @action
  public loadGoogleLoginInfo() {
    if (!this.rootStore.authStore.googleLoginInfo) return
    this.email = this.rootStore.authStore.googleLoginInfo.profile.email
    this.name = this.rootStore.authStore.googleLoginInfo.profile.name
    if (!this.name) this.name = this.email
  }

  @action
  public loadAppleLoginInfo() {
    const info = this.rootStore.authStore.appleLoginInfo
    if (!info) return
    this.email = info.email
    if (!info.user) return
    this.name = info.givenName + ' ' + info.familyName
  }

  @computed
  public get isValid() {
    if (!this.name) return false
    if (this.name === '') return false
    if (!this.email) return false
    if (this.email === '') return false
    if (!this.hasExternalAccount) {
      if (this.password === '') return false
      if (this.passwordConfirm === '') return false
    }
    return true
  }

  @computed
  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }

  @action
  public setName(val) {
    this.name = val
    this.clearErrorMessages()
  }

  @action
  public setBoardName(val) {
    this.newBoardName = val
    this.clearErrorMessages()
  }

  @action
  public setEmail(val) {
    this.email = val
    this.clearErrorMessages()
  }

  @action
  public setPassword(val) {
    this.password = val
    this.clearErrorMessages()
  }

  @action
  public setPasswordConfirm(val) {
    this.passwordConfirm = val
    this.clearErrorMessages()
  }

  @computed
  public get hasEmail(): boolean {
    return this.email && this.email !== ''
  }

  @computed
  public get hasName(): boolean {
    return this.name && this.name !== ''
  }

  @computed
  public get hasPassword(): boolean {
    return this.password && this.password !== ''
  }

  @computed
  public get hasPasswordConfirm(): boolean {
    return this.passwordConfirm && this.passwordConfirm !== ''
  }

  @computed
  public get showSpinner() {
    return this.isProcessing
  }

  @action
  public clearErrorMessages() {
    this.errorMessages = []
  }

  @computed
  public get googleLoginEmail() {
    return this.rootStore.authStore.googleLoginInfo.profile.email
  }

  @computed
  public get isCheckingGoogleLogin() {
    return this.rootStore.authStore.loginVM.showGoogleSpinner
  }

  @action
  public async continueWithGoogle() {
    this.rootStore.authStore.loginVM.continueWithGoogle()
  }

  @action
  public async continueWithApple() {
    this.rootStore.authStore.loginVM.continueWithApple()
  }

  @computed
  public get appleLoginEmail() {
    return this.rootStore.authStore.appleLoginInfo.email
  }

  @computed
  public get isCheckingAppleLogin() {
    return this.rootStore.authStore.loginVM.showAppleSpinner
  }

  @action
  public async submit() {
    if (this.password !== this.passwordConfirm) {
      this.errorMessages = ['Passwords do not match']
      return
    }
    this.errorMessages = []
    const form = {
      Name: this.name,
      Email: this.email,
      Password: this.password,
      acceptBoardInvitationGuid: null,
    }
    this.addGoogleInfo(form)
    this.addAppleInfo(form)
    if (this.boardInvitation && this.acceptBoardInvitation) {
      form.acceptBoardInvitationGuid = this.boardInvitation.BoardInvitationGuid
    }
    const success = await this.tryRegister(form)
    if (success) this.rootStore.appStore.navigateTo('/')
  }

  private addGoogleInfo(form) {
    if (!this.hasGoogleAccount) return
    form.GoogleUserId = this.rootStore.authStore.googleLoginInfo.profile.id
    form.GoogleEmail = this.rootStore.authStore.googleLoginInfo.profile.email
    form.FirstName = this.rootStore.authStore.googleLoginInfo.profile.givenName
    form.LastName = this.rootStore.authStore.googleLoginInfo.profile.familyName
  }

  private addAppleInfo(form) {
    console.log('here')
    console.log(this.hasAppleAccount)
    if (!this.hasAppleAccount) return
    form.AppleUserToken = this.rootStore.authStore.appleLoginInfo.identityToken
  }

  @computed
  public get hasGoogleAccount() {
    return Boolean(this.rootStore.authStore.googleLoginInfo)
  }

  @computed
  public get hasAppleAccount() {
    return Boolean(this.rootStore.authStore.appleLoginInfo)
  }

  @computed
  public get hasExternalAccount() {
    return this.hasAppleAccount || this.hasGoogleAccount
  }

  @computed
  public get canSignInWithApple(): boolean {
    if (this.rootStore.appStore.isAndroidNative) return false
    return true
  }

  @action
  public async loadBoardInvitation() {
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('inv')
    if (!id) return
    this.boardInvitation = await agent.BoardInvitations.get(id)
    if (this.boardInvitation) {
      runInAction(() => {
        this.setEmail(this.boardInvitation.ToEmailAddress)
        this.setPassword('')
        this.acceptBoardInvitation = true
      })
    }
  }

  @action
  public async tryRegister(form) {
    try {
      console.log(form)
      this.rootStore.appStore.firebaseSvc.logEvent('try_register', { method: form.GoogleUserId ? 'google' : 'email' })
      const results: Array<string> = await agent.Users.tryRegister(form)
      if (results[0] === 'Success' && results.length === 2) {
        this.rootStore.appStore.setToken(results[1])
        this.rootStore.userStore.loadUser()
        this.rootStore.loadData('user registered')
        this.rootStore.appStore.firebaseSvc.logEvent('sign_up', {
          method: form.GoogleUserId ? 'google' : form.AppleUserToken ? 'apple' : 'email',
        })
        return true
      } else {
        runInAction(() => {
          results.forEach((e) => this.errorMessages.push(e))
        })
      }
    } catch (e) {
      console.error(e)
    }
    return false
  }

  @action
  public goToLogin() {
    let url = '/login'
    if (this.boardInvitation) url += '?inv=' + this.boardInvitation.BoardInvitationGuid
    this.rootStore.appStore.navigateTo(url)
  }
}
