import React from 'react'
import { IonButton, IonList, IonItemDivider, IonItem, IonText, IonLabel } from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { Feather } from 'react-web-vector-icons'
import ChangePasswordModal from './ChangePasswordModal'
import { UserEditVM } from '../../view-models/UserEditVM'

interface Props {
  vm: UserEditVM
}

const AppleLogin: React.FC<Props> = ({ vm }) => {
  const renderChangePassword = () => {
    return <IonButton onClick={() => vm.openChangePasswordModal()}>Change Password</IonButton>
  }

  const renderChangePasswordModal = () => {
    if (!vm.changePasswordModalShown) return
    return <ChangePasswordModal onClose={() => vm.closeChangePasswordModal()} />
  }

  return (
    <>
      <IonItem lines={'inset'}>
        <IonLabel>
          <Feather name='lock' size={20} />
          Password
        </IonLabel>
        {renderChangePassword()}
      </IonItem>
      {renderChangePasswordModal()}
    </>
  )
}

export default inject('userStore')(observer(AppleLogin))
