import React from 'react'
import { IonItem, IonInput, IonLabel } from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { TextUtils } from '../../../shared/TextUtils'
import { UserEditVM } from '../../view-models/UserEditVM'

interface Props {
  vm: UserEditVM
}

const PersonalInfo: React.FC<Props> = ({ vm }) => {
  return (
    <>
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Name</IonLabel>
        <IonInput
          type='text'
          readonly={vm.changePasswordModalShown}
          name='sdafjasdflds'
          value={vm.name}
          onIonChange={(e: any) => vm.setName(e.target.value)}
          disabled={!vm.canEditPersonalInfo}
          ref={(e) => TextUtils.disableAutoComplete(e)}
        />
      </IonItem>
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Email</IonLabel>
        <IonInput
          type='text'
          readonly={vm.changePasswordModalShown}
          value={vm.emailAddress}
          onIonChange={(e: any) => vm.setEmailAddress(e.target.value)}
          disabled={!vm.canEditPersonalInfo}
          ref={(e) => TextUtils.disableAutoComplete(e)}
        />
      </IonItem>
    </>
  )
}

export default inject('userStore')(observer(PersonalInfo))
