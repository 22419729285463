import React from 'react'
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonText,
  IonBadge,
  IonSpinner,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonIcon,
} from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { AuthStore } from 'src/login/store/AuthStore'
import { callOutline, helpCircleOutline } from 'ionicons/icons'
import {
  EvilIcons,
  AntDesign,
  SimpleLineIcons,
  MaterialIcons,
  MaterialCommunityIcons,
  Feather,
  Entypo,
} from 'react-web-vector-icons'
import PanelCard from '../../shared/panel/PanelCard'

interface Props {
  authStore?: AuthStore
}

const InputForm: React.FC<Props> = ({ authStore }) => {
  const { loginVM: vm } = authStore
  if (!vm) return

  const renderErrorMessage = () => {
    if (!vm.errorMsg) return
    return (
      <div className='error'>
        <IonBadge color='danger'>{vm.errorMsg}</IonBadge>
      </div>
    )
  }

  const renderSpinner = () => {
    if (!vm.showSpinner) return
    if (vm.isIos) return <IonSpinner />
    return <IonSpinner name='crescent' />
  }

  const renderGoogleSpinner = () => {
    if (!vm.showGoogleSpinner) return
    if (vm.isIos) return <IonSpinner color='secondary' />
    return <IonSpinner name='crescent' color='secondary' />
  }

  const renderAppleSpinner = () => {
    if (!vm.showAppleSpinner) return
    if (vm.isIos) return <IonSpinner color='secondary' />
    return <IonSpinner name='crescent' color='secondary' />
  }

  const renderGuestSpinner = () => {
    if (!vm.showGuestSpinner) return
    if (vm.isIos) return <IonSpinner color='secondary' />
    return <IonSpinner name='crescent' color='secondary' />
  }

  const renderContinueWithGoogle = () => {
    return (
      <IonButton
        color='dark'
        className='google-button'
        expand='full'
        type='button'
        onClick={() => vm.continueWithGoogle()}
      >
        <AntDesign name='google' size={20} />
        &nbsp; Continue with Google
        {renderGoogleSpinner()}
      </IonButton>
    )
  }

  const renderContinueWithApple = () => {
    if (!vm.canSignInWithApple) return
    return (
      <IonButton
        color='dark'
        className='apple-button'
        expand='full'
        type='button'
        onClick={() => vm.continueWithApple()}
      >
        <AntDesign name='apple1' size={20} />
        &nbsp; Continue with Apple
        {renderAppleSpinner()}
      </IonButton>
    )
  }

  const renderContinueAsGuest = () => {
    return (
      <IonButton
        color='medium'
        className='guest-button'
        expand='full'
        type='button'
        onClick={() => vm.continueAsGuest()}
      >
        <SimpleLineIcons name='ghost' size={20} />
        &nbsp; Continue as Guest
        {renderGuestSpinner()}
      </IonButton>
    )
  }

  const renderLoginButton = () => {
    return (
      <IonButton color='success' className='login-button' expand='full' type='submit' onClick={() => vm.submit()}>
        <Entypo name='login' size={16} />
        &nbsp; Log In
        {renderSpinner()}
      </IonButton>
    )
  }

  const renderBoardInvitationInfo = () => {
    if (!vm.boardInvitation) return
    return (
      <IonCard className='board-info'>
        <IonCardContent>
          <strong>{vm.boardInvitation.FromName}</strong> has invited you to join the board &nbsp;
          <strong>{vm.boardInvitation.BoardName}</strong>
          <IonItem lines='none' onClick={() => vm.toggleAcceptBoardInvitation()}>
            <IonCheckbox checked={vm.acceptBoardInvitation} />
            &nbsp;&nbsp;Accept Invitation
          </IonItem>
        </IonCardContent>
      </IonCard>
    )
  }

  return (
    <>
      {renderBoardInvitationInfo()}
      <PanelCard>
        {renderContinueWithGoogle()}
        {renderContinueWithApple()}
        {renderContinueAsGuest()}
        <IonText className='or'>OR</IonText>
        <IonList>
          <IonItem>
            <IonLabel position='stacked'>Email</IonLabel>
            <IonInput
              type='text'
              id='email'
              name='email'
              onKeyPress={(e) => {
                if (e.charCode === 13) vm.submit()
              }}
              value={vm.email}
              onIonChange={(e: any) => vm.setEmail(e.target.value)}
            />
          </IonItem>
          <IonItem>
            <IonLabel position='stacked'>Password</IonLabel>
            <IonInput
              type='password'
              id='password'
              name='password'
              value={vm.password}
              onKeyPress={(e) => {
                if (e.charCode === 13) vm.submit()
              }}
              onIonChange={(e: any) => vm.setPassword(e.target.value)}
            />
          </IonItem>
        </IonList>
        {renderLoginButton()}
        {renderErrorMessage()}
        <IonButton
          className='forgot-password-button'
          color='tertiary'
          expand='full'
          fill='clear'
          type='button'
          onClick={() => vm.goToForgotPassword()}
        >
          <Feather name='send' size={21} />
          &nbsp; Forgot Password?
        </IonButton>
        <br />
        <IonButton
          color='tertiary'
          className='register-button'
          expand='full'
          type='button'
          onClick={() => vm.goToRegister()}
        >
          <EvilIcons name='envelope' size={28} />
          Register with Email
        </IonButton>
        <br />
        <IonButton
          className='trouble-button'
          color='danger'
          expand='full'
          fill='clear'
          type='button'
          onClick={() => vm.goToContactPage()}
        >
          <Entypo size={21} name='emoji-sad' />
          &nbsp; Trouble logging in?
        </IonButton>
      </PanelCard>
    </>
  )
}

export default inject('authStore')(observer(InputForm))
