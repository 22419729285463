import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import agent from '../../Agent'
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in'
import { SocialLogin } from '@capgo/capacitor-social-login'
import Environment from '../../environment/Environment'

export class UserEditVM {
  private rootStore: RootStore
  @observable private isDirty = false

  constructor(rootStore: RootStore) {
    makeObservable(this)
    this.rootStore = rootStore
    this.loadUserDetails()
    this.checkForGuestUserRedirect()
  }

  private checkForGuestUserRedirect() {
    if (!this.rootStore.userStore.isGuestUser) return
    this.rootStore.appStore.navigateTo('/guestuser', true)
  }

  @action
  private loadUserDetails() {
    this.name = this.rootStore.userStore.user.Name
    this.emailAddress = this.rootStore.userStore.user.EmailAddress
  }

  @observable public name: string = ''
  @observable public emailAddress: string = ''
  @observable public changePasswordModalShown: boolean = false

  @action
  public openChangePasswordModal() {
    setTimeout(() => (this.changePasswordModalShown = true), 100)
  }

  @action
  public closeChangePasswordModal() {
    setTimeout(() => (this.changePasswordModalShown = false), 100)
  }

  @action
  public setName(value: string) {
    this.isDirty = true
    this.name = value
  }

  @action
  public setEmailAddress(value: string) {
    this.isDirty = true
    this.emailAddress = value
  }

  @computed
  public get saveEnabled() {
    if (!this.isDirty) return false
    if (!this.isValid) return false
    return true
  }

  @action
  public cancel() {
    this.isDirty = false
    this.loadUserDetails()
    setTimeout(() => (this.isDirty = false), 100)
  }

  @action
  public goBack() {
    this.rootStore.appStore.handleGoBack()
  }

  @computed
  public get hasGoogleLogin() {
    if (!this.rootStore.userStore.user) return
    return this.rootStore.userStore.user.HasGoogleLogin
  }

  @computed
  public get hasAppleLogin() {
    if (!this.rootStore.userStore.user) return
    return this.rootStore.userStore.user.HasAppleLogin
  }

  @computed
  public get isValid(): boolean {
    if (this.name === '') return false
    if (this.emailAddress === '') return false
    return true
  }

  @action
  public async save() {
    const form = {
      Name: this.name,
      EmailAddress: this.emailAddress,
    }
    this.rootStore.userStore.user.setName(this.name)
    this.rootStore.userStore.user.setEmailAddress(this.emailAddress)
    await this.saveOnServer(form)
    this.rootStore.appStore.navigateTo('/settings')
  }

  public async saveOnServer(form, isRetry = false) {
    try {
      await agent.Users.save(form)
    } catch (e) {
      if (isRetry) return false
      this.rootStore.actionsStore.addAction(0, form)
    }
    return true
  }

  @action
  public async connectGoogle() {
    await SocialLogin.initialize({
      google: {
        webClientId: '151188122140-glpdjmvcolr8pqc52g4i5ip8kjaelc6i.apps.googleusercontent.com',
      },
    })
    const result = await SocialLogin.login({
      provider: 'google',
      options: {
        scopes: ['email', 'profile'],
      },
    })
    if (result.result.profile.email) {
      var apiResult = await agent.Users.addGoogleLogin(result.result.profile)
      alert(apiResult)
      await this.rootStore.userStore.loadUser()
    }
  }

  @computed
  public get canEditPersonalInfo() {
    if (this.rootStore.userStore.isGuestUser) return false
    return true
  }

  @computed
  public get hasPassword() {
    return !this.rootStore.userStore.isGuestUser
  }

  @action
  public async connectApple() {
    let options: SignInWithAppleOptions = {
      clientId: Environment.appleClientId,
      redirectURI: Environment.appleRedirectUri,
      scopes: Environment.appleScopes,
      state: Environment.appleState,
      nonce: Environment.appleNonce,
    }
    const result: SignInWithAppleResponse = await SignInWithApple.authorize(options)
    if (result.response && result.response.identityToken) {
      var apiResult = await agent.Users.addAppleLogin(result.response)
      alert(apiResult)
      await this.rootStore.userStore.loadUser()
    }
  }
}
