import { RootStore } from 'src/stores/RootStore'
import { computed } from 'mobx'
import { GroupRowVM } from '@elexient/elexiapp.bits.shared'
import { RecipesListVM } from './RecipesListVM'
import Environment from '../../../environment/Environment'

export class RecipeGroupRowVM extends GroupRowVM<RootStore, RecipesListVM> {
  public color: string = ''

  public get height() {
    return Environment.listRowHeight
  }

  @computed
  public get displayName(): string {
    return this.name
  }
}
