import { computed, action, observable, makeObservable } from 'mobx'
import { ListItemsListVM } from './ListItemsListVM'
import { ListItem } from '../aggregate/ListItem'
import { ListItemsService } from '../services/ListItemsService'
import { FutureItemVM } from './FutureItemVM'
import { RowVM } from '@elexient/elexiapp.bits.shared'
import { RootStore } from '../../stores/RootStore'
import { Item } from '../aggregate/Item'
import { deserialize } from 'serializr'
import { Category } from '../aggregate/Category'
import Environment from '../../environment/Environment'

export class ListItemRowVM extends RowVM<RootStore, ListItemsListVM, ListItem> {
  private keepTimeout: NodeJS.Timer

  constructor(rootStore: RootStore, listVM: ListItemsListVM, record: ListItem) {
    super(rootStore, listVM, record, ListItemRowVM)
    makeObservable(this)
  }

  @observable public rightOptionsShown: boolean = false

  @computed
  public get backgroundColor(): string {
    if (!this.isGotten) return undefined
    return this.rootStore.appStore.themeGenVM.lighterColor
  }

  @action
  public toggleRightOptions() {
    this.rightOptionsShown = !this.rightOptionsShown
  }

  public get keyword(): string {
    return this.name
  }

  public get height(): number {
    if (this.isHidden) return 0
    return Environment.listRowHeight
  }

  public type: string = 'item'

  public get listItem() {
    return this.record
  }

  public get key(): string {
    return this.listItem.ListItemGuid
  }

  @computed
  public get name(): string {
    if (!this.item) return 'not loaded'
    return this.item.Name
  }

  @computed
  public get listItemGuid(): string {
    return this.listItem.ListItemGuid
  }

  @computed
  public get categoryGuid(): string {
    if (!this.item) return null
    return this.item.CategoryGuid
  }

  @computed
  public get categoryName(): string {
    if (!this.item) return 'Uncategorized'
    if (!this.item.Category) return 'Uncategorized'
    const name = this.item.Category.Name
    if (name === '(Not Set)') return 'Uncategorized'
    return this.item.Category.Name
  }

  @computed
  public get categoryColor(): string {
    return this.item.Category.Color
  }

  @computed
  public get itemGuid(): string {
    return this.listItem.ItemGuid
  }

  @computed
  public get quantity(): number {
    if (this.forceQuantity) return this.futureItems.length
    return this.listItem.Quantity
  }

  @computed
  public get forceQuantity(): boolean {
    if (this.futureItems.length > 1 && this.listItem.Quantity <= this.futureItems.length) return true
    return false
  }

  @computed
  public get hasQuantity(): boolean {
    return this.quantity !== 0
  }

  @computed
  public get isGotten(): boolean {
    return this.listItem.isGotten
  }

  @computed
  public get isCleared(): boolean {
    return this.listItem.isCleared
  }

  @computed
  public get item(): Item {
    if (!this.listItem.Item) {
      const mainItem = this.rootStore.itemsStore.get(this.listItem.ItemGuid)
      if (!mainItem) return null
      const mainCategory = this.rootStore.categoriesStore.get(mainItem.CategoryGuid)
      const item = deserialize(Item, mainItem.toDTO())
      item.Category = deserialize(Category, mainCategory.toDTO())
      return item
    }
    return this.listItem.Item
  }

  @computed
  public get hasNotes(): boolean {
    if (this.notes !== '') return true
    if (this.futureItems.length !== 0) return true
    return false
  }

  @computed
  public get notes(): string {
    let notes = ''
    if (this.quantity > 1) notes += 'x' + this.quantity.toString()
    if (notes !== '' && this.item && this.item.hasNotes) notes += ', '
    if (this.itemNotes) notes += this.item.Notes
    if (notes !== '' && this.listItem && this.listItem.hasNotes) notes += ', '
    if (this.listItem && this.listItem.hasNotes) notes += this.listItem.Notes
    return notes
  }

  @computed
  public get itemNotes(): string {
    if (!this.item) return ''
    return this.item.Notes
  }

  @computed
  public get keepUp(): boolean {
    return this.listVM.keepUps.includes(this.listItemGuid)
  }

  @computed
  public get keepDown(): boolean {
    return this.listVM.keepDowns.includes(this.listItemGuid)
  }

  @action
  public toggleGotten() {
    this.listItem.toggleGotten()
    if (this.isGotten) {
      this.listVM.addKeepUp(this.listItemGuid)
    } else {
      this.listVM.addKeepDown(this.listItemGuid)
    }
    this.listVM.resetKeepTimeout()
    const svc = new ListItemsService(this.rootStore)
    svc.save(this.listItem.toDTO())
  }

  public markAsCleared() {
    this.listItem.markAsCleared()
    const svc = new ListItemsService(this.rootStore)
    svc.save(this.listItem.toDTO())
  }

  @computed
  public get rank(): string {
    if (this.keepUp) return this.name.toLowerCase()
    if (this.keepDown) return 'zz' + this.name.toLowerCase()
    if (this.isGotten) return 'zz' + this.name.toLowerCase()
    return this.name.toLowerCase()
  }

  @action
  public click() {
    if (this.listVM.slidingElement === this.slidingRef && this.listVM.slidingElementDidMove) {
      return
    }
    this.rootStore.appStore.history.push('/itemedit/' + this.listItem.ItemGuid)
  }

  @action
  public increaseQuantity(qty: number = 0) {
    if (this.forceQuantity && this.listItem.Quantity < this.quantity) {
      this.listItem.setQuantity(this.quantity)
    }
    this.listItem.increaseQuantity(qty)
  }

  @action
  public decreaseQuantity() {
    if (this.forceQuantity) return
    this.listItem.decreaseQuantity()
  }

  @action
  public deleteFromShoppingList() {
    const svc = new ListItemsService(this.rootStore)
    if (!this.isGotten) {
      svc.delete(this.listItem.toDTO())
      return
    }
    this.listItem.markAsCleared()
    svc.save(this.listItem.toDTO())
  }

  @computed
  public get className(): string {
    // if (this.isGotten && this.keepUp) return 'gotten'
    if (this.isGotten) return 'gotten gotten-perm'
  }

  @computed
  public get futureItems(): FutureItemVM[] {
    const futureItems = []
    this.rootStore.mealsStore.currentBoardRecords
      .filter((e) => e.isInFuture)
      .forEach((meal) => {
        meal.MealRecipes.forEach((rcp) => {
          const foundRecipe = this.rootStore.recipesStore.get(rcp.RecipeGuid)
          if (!foundRecipe) return
          const foundItems = foundRecipe.getRecipeItemsForItem(this.itemGuid)
          foundItems.forEach((e) => {
            const item = this.rootStore.itemsStore.get(this.item.ItemGuid)
            futureItems.push(new FutureItemVM(this.rootStore, meal, foundRecipe, e, item))
          })
        })
        meal.MealItems.filter((e) => e.ItemGuid === (this.item ? this.item.ItemGuid : 'nope')).forEach((e) => {
          const foundItem = this.rootStore.itemsStore.get(e.ItemGuid)
          if (!foundItem) return
          futureItems.push(new FutureItemVM(this.rootStore, meal, undefined, undefined, foundItem))
        })
      })
    return futureItems
  }

  @computed
  public get hasImage(): boolean {
    return this.item.hasImage
  }

  @computed
  public get itemItemsFeatureEnabled() {
    return this.rootStore.featuresStore.hasItemImagesFeature
  }

  @computed
  public get image() {
    const img = this.rootStore.storageFilesStore.get(this.item.Attachment.StorageFileGuid)
    return img
  }
}
