import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import BoardInvitation from '../aggregate/BoardInvitation'
import { BoardInvitationsService } from '../service/BoardInvitationsService'
import { TextUtils } from '../../shared/TextUtils'

export class BoardInvitationNewVM {
  private rootStore: RootStore
  private emailInputEl: HTMLIonInputElement
  @observable private boardInvitation: BoardInvitation
  private focused: boolean = false

  constructor(rootStore: RootStore) {
    makeObservable(this)
    this.rootStore = rootStore
    this.boardInvitation = BoardInvitation.create(this.rootStore.boardsStore.currentBoard.BoardGuid)
  }

  @observable public isOpen: boolean = true
  @observable public isProcessing: boolean = false

  @computed
  public get toEmailAddress(): string {
    return this.boardInvitation.ToEmailAddress
  }

  @action
  public setToEmailAddress(val) {
    this.boardInvitation.setToEmailAddress(val)
  }

  @computed
  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }

  public focusEmailInput() {
    if (!this.emailInputEl) return
    this.emailInputEl.focus()
  }

  @action
  public async setEmailInput(e: HTMLIonInputElement) {
    if (this.focused) return
    setTimeout(() => e.setFocus(), 1000)
    this.focused = true
    TextUtils.disableAutoComplete(e)
  }

  @computed
  public get isValid(): boolean {
    if (!this.toEmailAddress || this.toEmailAddress === '') return false
    return true
  }

  @action
  public save() {
    const svc = new BoardInvitationsService(this.rootStore)
    svc.save(this.boardInvitation.toDTO())
    this.toggle()
  }

  @action
  public toggle() {
    this.isOpen = !this.isOpen
  }
}
