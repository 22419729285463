import React from 'react'
import { IonCard, IonCardContent } from '@ionic/react'
import './PanelCard.scss'

const PanelCard = ({ children, ...props }) => {
  return (
    <div id='PanelCard' {...props}>
      {/* <IonCardContent> */}
        <div className='body'>{children}</div>
      {/* </IonCardContent> */}
    </div>
  )
}

export default PanelCard
