import React from 'react'
import { IonCard, IonCardContent, IonChip, IonText } from '@ionic/react'
import { AppStore } from '../stores/AppStore'
import { UserStore } from '../user/store/UserStore'
import { inject, observer } from 'mobx-react'
import './MobileNudgeCard.scss'
import { AntDesign } from 'react-web-vector-icons'
import QRCode from '../assets/images/GB_Store_QR_Code_Dark.png'
import { faUnlock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  appStore?: AppStore
  userStore?: UserStore
}

const MobileNudgeCard: React.FC<Props> = ({ appStore, userStore }) => {
  if (!appStore.isWindows) return null

  return (
    <IonCard id='MobileNudgeCard'>
      <div
        id='UnlockMessage'
        className={!userStore.isGuestUser ? 'hidden' : ''}
        onClick={() => appStore.navigateTo('/guestuser')}
      >
        <AntDesign name='unlock' size={24} />
        <IonText>Register to pair your phone</IonText>
      </div>
      <div className={userStore.isGuestUser && 'blurred'}>
        <IonCardContent>
          <img src={QRCode} alt='QR-Code' />
          <IonText>
            <strong>Scan the QR Code</strong>
            <br />
            to pair your phone
          </IonText>
          <div className='chips'>
            <IonChip className='ios' onClick={() => appStore.navigateTo('/redirect/ios')}>
              <AntDesign name='apple1' size={16} />
              <IonText>iOS</IonText>
            </IonChip>
            <IonChip className='android' onClick={() => appStore.navigateTo('/redirect/android')}>
              <AntDesign name='google' size={16} />
              <IonText>Android</IonText>
            </IonChip>
          </div>
        </IonCardContent>
      </div>
    </IonCard>
  )
}

export default inject('appStore', 'userStore')(observer(MobileNudgeCard))
