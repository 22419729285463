import { MealCategoryRowVM } from './MealCategoryRowVM'
import { MealCategory } from './../../../meal-categories/aggregate/MealCategory'
import { MealsStore } from '../../../meals/store/MealsStore'
import { RootStore } from '../../../stores/RootStore'
import { computed, observable, action, makeObservable, set } from 'mobx'
import { MealChipVM } from './MealChipVM'
import { DayGroupVM } from './DayGroupVM'
import moment, { Moment } from 'moment'

export class MealsListVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @observable public menuShown: boolean = false
  @observable public event: any
  @observable public startDate: Date = moment().startOf('day').toDate()

  @computed
  public get mealCategories(): Array<MealCategory> {
    if (!this.rootStore.mealCategoriesStore) return []
    return this.rootStore.mealCategoriesStore.currentBoardRecords.sort((a, b) => (a.Rank < b.Rank ? -1 : 1))
  }

  private get mealsStore(): MealsStore {
    return this.rootStore.mealsStore
  }

  public goToToday() {
    this.startDate = moment().startOf('day').toDate()
  }

  public async refresh() {
    this.rootStore.setLoadingProgress(50)
    const proms = [this.rootStore.mealsStore.loadData(), this.rootStore.mealCategoriesStore.loadData()]
    await Promise.all(proms)
    setTimeout(() => this.rootStore.setLoadingProgress(100), 300)
  }

  @computed
  public get mealChips(): Array<MealChipVM> {
    return this.mealsStore.currentBoardRecords.map((e) => new MealChipVM(this.rootStore, e))
  }

  @computed
  public get isSeeding(): boolean {
    return this.rootStore.appStore.isSeeding
  }

  @computed
  public get loadProgress() {
    return this.rootStore.loadProgress
  }

  @computed
  public get progressHidden(): boolean {
    return this.rootStore.progressHidden
  }

  @computed
  public get hasVisibleMeals() {
    let cnt = 0
    this.dayGroups.forEach((e) => (cnt += e.availableMealCategories.length))
    if (cnt !== 0) return true
    return false
  }

  @action
  public decreaseStartDate() {
    setTimeout(() => {
      this.startDate = moment(this.startDate)
        // .startOf('day')
        .add(-1, 'day')
        .toDate()
    }, 200)
  }

  @action
  public inccreaseStartDate() {
    setTimeout(() => {
      this.startDate = moment(this.startDate)
        // .startOf('day')
        .add(1, 'day')
        .toDate()
    }, 200)
  }

  @computed
  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }

  @computed
  public get dayGroups(): Array<DayGroupVM> {
    // console.time('dayGroups')
    const currentDate: Moment = moment(this.startDate)
    const forDays: Moment[] = []
    forDays.push(currentDate.startOf('day').clone())
    for (let i = 0; i < 7; i++) forDays.push(currentDate.add(1, 'day').clone())
    const returnVMs = []
    forDays.forEach((forDay) => {
      const mealCats = this.mealCategories.map((cat) => {
        const mealsThisCat = this.getMealsForDayAndCat(forDay, cat.MealCategoryGuid)
        return new MealCategoryRowVM(this.rootStore, this, cat.MealCategoryGuid, mealsThisCat)
      })
      returnVMs.push(new DayGroupVM(this.rootStore, forDay, null, mealCats))
    })
    // console.timeEnd('dayGroups')
    return returnVMs
  }

  private getMealsForDayAndCat(forDay: Moment, mealCategoryGuid) {
    // console.time('getMealsForDayAndCat')
    const availableMeals = this.mealsStore.sortedCurrentBoardRecords
    const returnMeals = []
    for (var i = 0; i < availableMeals.length; i++) {
      var e = availableMeals[i]
      if (e.MealCategoryGuid === mealCategoryGuid && e.localPlannedDateTime.isSame(forDay, 'D')) {
        returnMeals.push(new MealChipVM(this.rootStore, e))
      }
      if (e.localPlannedDateTime.isBefore(this.startDate, 'D')) break
    }
    // console.timeEnd('getMealsForDayAndCat')
    return returnMeals
  }

  public goToSetup(): void {
    this.menuShown = false
    this.rootStore.appStore.history.push('/mealcategorieslist')
  }

  @action
  public openMenu(e: React.MouseEvent) {
    e.persist()
    this.event = e
    this.menuShown = true
  }

  @action
  public async sync() {
    await this.refresh()
    this.hideMenu()
  }

  @action
  public hideMenu() {
    this.menuShown = false
  }
}
